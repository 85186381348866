import { EyeFaceProtectionValues, HardHatValues, HearingProtectionValues, IRequestItem, LegProtectionValues, SafetyVestValues, WorkGlovesValues } from './requestItem';
import { IEmployee } from '../user';


export type IRequestStatus =
  | 'Requested'
  | 'Reviewed'
  | 'Awaiting Additional Review'
  | 'Void';

export type IRequestEmployeeStatus =
  | 'Pending'
  | 'Approved'
  | 'Rejected'
  | 'Additional Review'
  | 'Void';

export interface IRequest {
  id?: number;
  dsc: string;
  dscEmail: string;
  discipline: string;
  requester: string;
  requesterEmail: string;
  createdTime?: Date;
  reviewer?: string;
  reviewed?: Date;
  status: IRequestStatus;
  requestEmployees: IRequestEmployee[];
  newHire?: boolean;
}

export class RequestFormValues implements IRequest {
  dsc: string = '';
  dscEmail: string = '';
  discipline: string = '';
  requester: string = '';
  requesterEmail: string = '';
  status: IRequestStatus = 'Requested' as IRequestStatus;
  requestEmployees: IRequestEmployee[] = [new RequestEmployeeFormValues(),];
}

export interface IRequestEmployee {
  id?: number;
  requestedEmployee: IEmployee | null;
  employeeNumber: string;
  name: string;
  trainingCompleted?: boolean;
  coBrand: string;
  office: string;
  shipTo: string;
  projectNo: string;
  task: string;
  org: string;
  status: IRequestEmployeeStatus;
  requestItems: IRequestItem[];
  comments: string;
  isOrderShipped?: boolean;
  shipper?: string;
  shippedTime?: Date;
  email?: string;
  workFit?: boolean;
  isReassessed?: boolean;
  reassessedBy?: string;
  reassessedTime?: Date;
  internalComments?: IComment[];
}

export class RequestEmployeeFormValues implements IRequestEmployee {
  employeeNumber: string = '';
  requestedEmployee: IEmployee | null = null;
  name: string = '';
  trainingCompleted?: boolean | undefined;
  coBrand: string = '';
  office: string = '';
  shipTo: string = '';
  projectNo: string = '';
  task: string = '';
  org: string = '';
  comments: string = '';
  status: IRequestEmployeeStatus = 'Pending';
  requestItems: IRequestItem[] = [];
  email: string = '';
}

export class NewHireBundleRequestEmployeeFormValues implements IRequestEmployee {
  employeeNumber: string = '';
  requestedEmployee: IEmployee | null = null;
  name: string = '';
  trainingCompleted?: boolean | undefined;
  coBrand: string = '';
  office: string = '';
  shipTo: string = '';
  projectNo: string = '';
  task: string = '';
  org: string = '';
  comments: string = '';
  status: IRequestEmployeeStatus = 'Pending';
  email: string = '';
  requestItems: IRequestItem[] = [
    new SafetyVestValues(),
    new WorkGlovesValues(),
    new HardHatValues(),
    new EyeFaceProtectionValues(),
    new HearingProtectionValues(),
    new LegProtectionValues(),
  ];
}

export interface IPpeManager {
  id: number;
  name: string;
  email: string;
  isDSC: boolean;
  isSafetyManager: boolean;
  deleted: boolean;
  createdTime: Date;
}

export interface IHomeDepartment {
  id: number;
  homeDepartmentName: string;
  homeDepartmentCode: string;
  divisionId: number;
  divisionName: string;
  disciplineSafetyCoordinatorsName: string;
  disciplineSafetyCoordinatorsEmail: string;
  disciplineLeadsName: string;
  disciplineLeadsEmail: string;
  updateByName: string;
  updateByEmail: string;
  updateDate: string | null;
  modified: boolean;
}

export interface IComment {
  id?: string;
  created?: Date;
  createdByName: string;
  createdByEmail: string;
  lastModified?: Date;
  lastModifiedByName?: string;
  lastModifiedByEmail?: string;
  message: string;
}

export class CommentFormValues implements IComment {
  createdByName: string = '';
  createdByEmail: string = '';
  message: string = '';
}