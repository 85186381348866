import { styled, useMediaQuery, useTheme } from '@mui/material';

export const stringAvatar = (name?: string) => {
  if (name) {
    return {
      children: name.includes(' ')
        ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
        : `${name[0]}`,
    };
  }
};

export const isImage = (fileName: string): boolean =>
  ['jpg', 'jpeg', 'png', 'gif'].includes(fileName.toLowerCase().split('.')[1]);

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const useSmallScreen = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
};

export const useExtraSmallScreen = () => {
  return useMediaQuery('(max-width: 500px)');
};

export const firstImageSrc = (images?: any[]) => {
  return (
    process.env.REACT_APP_ATTACHMENTS_URL +
    '/' +
    images?.filter((i) => i !== '')[0]
  );
};

// export const timestampLocalizer = (date: Date) => {
//   if(date){
//     let newDate = new Date(date);
//     let utcDate = new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDay(), newDate.getHours(), newDate.getMinutes(), newDate.getSeconds()));
//     return utcDate.toLocaleString();
//   }
//   return 'Invalid Date';
// };

export const timestampLocalizer = (dateTimeFromObject: Date): string => {
  if(dateTimeFromObject){
    let dateTime = new Date(dateTimeFromObject);
    return new Date(dateTime.getTime() - dateTime.getTimezoneOffset() * 60000).toLocaleString([], {dateStyle:'short', timeStyle: 'short' });
  }
  return 'Invalid Date';
}

export const formatQuantity = (quantity: number | undefined, unit: string | undefined) => {
  if(quantity && unit){
    let plural = quantity > 1;
    switch(unit) {
      case "Each":
        return `${quantity} Each`;
      case "Pair":
        return plural ? `${quantity} Pairs` : `${quantity} Pair`;
      case "Pack":
        return plural ? `${quantity} Packs` : `${quantity} Pack`;
      case "Bag":
        return plural ? `${quantity} Bags` : `${quantity} Bag`;
      case "Box":
        return plural ? `${quantity} Boxes` : `${quantity} Box`;
      case "Case":
        return plural ? `${quantity} Case` : `${quantity} Cases`;
      default:
        return quantity;
    }
  }
  return quantity;
}