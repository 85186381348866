
import React, { useEffect, useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Paper,
  SelectChangeEvent,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { ppeItems } from 'app/common/options/ppeOptions';
import { CommentFormValues, IRequest, IRequestEmployee } from 'app/models/ppeRequest/request';
import { IRequestItem } from 'app/models/ppeRequest/requestItem';
import { useModalStore } from 'app/stores/modalStore';
import { useRequestStore } from 'app/stores/requestStore';
import { useUserStore } from 'app/stores/userStore';
import { useNavigate, useParams } from 'react-router-dom';
import { formatQuantity, timestampLocalizer } from 'app/common/util/util';
import RequestDropdown from 'features/PPERequest/RequestDropdown';
import RequestTextField from 'features/PPERequest/RequestTextField';
import Loading from 'app/common/modals/Loading';
import NotFound from 'app/common/modals/NotFound';
import ReactToPrint from 'react-to-print';
import AddIcon from '@mui/icons-material/Add';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CancelIcon from '@mui/icons-material/Cancel';
import GradeIcon from '@mui/icons-material/Grade';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import PrintIcon from '@mui/icons-material/Print';
import BlockIcon from '@mui/icons-material/Block';
import RequestHeader from './RequestHeader';
import RequestDetailsTable from './RequestDetailsTable';

const RequestDetails = () => {
  /* State and functions from custom hooks */
  const { requestPageSize, requestPage, requestId }: any = useParams();
  const {
    requestForReview,
    requestForReviewLoading,
    setRequestForReview,
    handleRequest,
    voidRequestEmployee,
    voidRequestEmployeeLoading,
    createInternalComment,
    createInternalCommentLoading
  } = useRequestStore();
  const { user } = useUserStore();
  const { setSnackBar } = useModalStore();
  const navigate = useNavigate();
  const componentRef = useRef(null);

  /* Define component state variables */
  const [request, setRequest] = useState<IRequest>({} as IRequest);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const [newInternalComments, setNewInternalComments] = useState([] as CommentFormValues[]);

  const initialItemState = {
    category: '',
    item: '',
    type: '',
    color: '',
    size: '',
    make: '',
    model: '',
    quantity: undefined,
    isStandard: undefined,
    itemId: undefined,
    isAdminAdded: true,
  };

  /* Function to handle removing item */
  const removeItem = (employeeIndex: number, itemIndex: number) => {
    const updatedEmployees = [...request.requestEmployees];
    const updatedEmployee = { ...updatedEmployees[employeeIndex] };
    updatedEmployee.requestItems.splice(itemIndex, 1);
    updatedEmployees[employeeIndex] = updatedEmployee;
    setRequest({
      ...request,
      requestEmployees: updatedEmployees,
    });
  };

  /* Function to handle adding item */
  const addItem = (employeeIndex: number) => {
    const updatedEmployees = [...request.requestEmployees];
    const updatedEmployee = { ...updatedEmployees[employeeIndex] };
    updatedEmployee.requestItems.push({ ...initialItemState });
    updatedEmployees[employeeIndex] = updatedEmployee;
    setRequest({
      ...request,
      requestEmployees: updatedEmployees,
    });
  };

  const handleVoidRequestEmployee = (requestEmployee: IRequestEmployee) => {
    let updatedRequestEmployee: IRequestEmployee = {
      ...requestEmployee,
      status: 'Void',
      isReassessed: true,
      reassessedBy: user?.displayName!
    };
    voidRequestEmployee(updatedRequestEmployee)
    .then((response) => {
      if (response?.status === 200) {
        setRequestForReview(requestId);
         setSnackBar({
           open: true,
           type: 'success',
           content: `Employee Request has been marked void.`,
         });
       } else {
         setSnackBar({
           open: true,
           type: 'error',
           content: `Unable to void Employee Request.`,
         });
       }
     })
     .catch((error) => console.log(error));
  };

  const submitInternalComment = (requestEmployeeId: number, requestEmployeeIndex: number) => {
    if(requestForReview && newInternalComments[requestEmployeeIndex].message !== ''){
      newInternalComments[requestEmployeeIndex].createdByName = user?.displayName!;
      newInternalComments[requestEmployeeIndex].createdByEmail = user?.userPrincipalName!;
      createInternalComment(requestEmployeeId, newInternalComments[requestEmployeeIndex])
        .then((response) => {
          if (response?.status === 200) {
            setRequestForReview(requestId);
            setSnackBar({
              open: true,
              type: 'success',
              content: `Internal Comment added to Employee Request.`,
            });
          } else {
            setSnackBar({
              open: true,
              type: 'error',
              content: `Unable to add Internal Comment.`,
            });
          }
        })
        .catch((error) => console.log(error));
    };
  }

  /* Handle form submission */
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setSubmitLoading(true);

    handleRequest(request)
      .then((response) => {
        if (response?.status === 200) {
          setRequestForReview(requestId);
        } else {
          setSnackBar({
            open: true,
            type: 'error',
            content: `Request submitting is not completed.`,
          });
        }
        setSubmitLoading(false);
      })
      .catch((error) => console.log(error));
  };

  /* Fetch request details on component mount */
  useEffect(() => {
    setRequestForReview(requestId);
  }, [requestId, setRequestForReview]);

  /* Update request details when fetched */
  useEffect(() => {
    if (requestForReview) {
      const updatedRequest: IRequest = {
        ...requestForReview,
        requestEmployees: requestForReview.requestEmployees.map((employee) => ({
          ...employee,
          status: employee.status === 'Pending' || employee.status === 'Additional Review' ? 'Approved' : employee.status,
        })),
        status: 'Reviewed',
        reviewer: user?.displayName!,
      };
      setRequest(updatedRequest);
      if(requestForReview.requestEmployees.length > 0){
        let tempArray = [] as CommentFormValues[];
        requestForReview.requestEmployees.forEach(() => {
          tempArray.push(new CommentFormValues());
        });
        setNewInternalComments(tempArray)
      }
    }
  }, [requestForReview, user]);

  /* Render loading state while request details are loading */
  /* Render not found state if request details are not available */
  if (requestForReviewLoading) {
    return <Loading />;
  } else if (!requestForReview) {
    return <NotFound />;
  }

  return (
    <Box mx={2} mt={2} component="form" onSubmit={handleSubmit}>
      {/* Buttons for navigation and printing */}
      <Box m={2} display="flex" justifyContent="space-between" alignItems="center">
        <Button
          variant="contained"
          size="small"
          startIcon={<KeyboardReturnIcon />}
          onClick={() => { navigate(`/admin/ppeRequest/${requestPageSize}/${requestPage}`);}}
        >
          Back to table
        </Button>
        <ReactToPrint
          content={() => componentRef.current}
          trigger={() => (
            <Button variant="contained" size="small" startIcon={<PrintIcon />}>
              Print Request
            </Button>
          )}
        />
      </Box>
      <Box ref={componentRef}>
        {/* Request header */}
        <RequestHeader ppeRequest={requestForReview} />
        {/* Request details table */}
        <RequestDetailsTable ppeRequest={requestForReview} />
        {/* Request details table for each employee */}
        <Box mt={2}>
          {(requestForReview.status === 'Requested' || requestForReview.status === 'Awaiting Additional Review' ? request: requestForReview)?.requestEmployees?.map((requestEmployee, requestEmployeeIndex) => (
            <Box key={requestEmployee.id} component={Paper} my={2}>
              {/* print page break */}
              <Box sx={{ '@media print': { marginTop: '1rem', display: 'block', pageBreakBefore: 'always',},}}></Box>
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                  <Chip label={requestEmployeeIndex + 1} sx={{ m: 1 }} />
                </Box>
                <TableContainer component={Paper} elevation={2}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{'& th': { color: 'white', backgroundColor: 'primary.main', },}}>
                        <TableCell>Employee Number</TableCell>
                        <TableCell>Recipient Name</TableCell>
                        <TableCell>Co Brand</TableCell>
                        <TableCell>Office</TableCell>
                        <TableCell>Ship To</TableCell>
                        <TableCell>Training Completed</TableCell>
                        {requestForReview.newHire && (<TableCell>WorkFit</TableCell>)}
                        <TableCell>Project No</TableCell>
                        <TableCell>Task</TableCell>
                        <TableCell>Org</TableCell>
                        <TableCell>Comments</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{requestEmployee.employeeNumber}</TableCell>
                        <TableCell>{requestEmployee.name}</TableCell>
                        <TableCell>{requestEmployee.coBrand}</TableCell>
                        <TableCell>{requestEmployee.office}</TableCell>
                        <TableCell>{requestEmployee.shipTo}</TableCell>
                        <TableCell>{requestEmployee.trainingCompleted ? 'Yes' : 'No'}
                        </TableCell>{requestForReview.newHire && (<TableCell>{requestEmployee.workFit ? 'Yes' : 'No'}</TableCell> )}
                        <TableCell>{requestEmployee.projectNo}</TableCell>
                        <TableCell>{requestEmployee.task}</TableCell>
                        <TableCell>{requestEmployee.org}</TableCell>
                        <TableCell>{requestEmployee.comments}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* Request details table for each item */}
                <Box m={2}>
                  <TableContainer component={Paper} elevation={1} key={requestEmployee.id}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow sx={{'& th': { backgroundColor: 'secondary.dark', },}} >
                          <TableCell>Category</TableCell>
                          <TableCell>Item</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Size</TableCell>
                          <TableCell>Color</TableCell>
                          <TableCell>Make</TableCell>
                          <TableCell>Model</TableCell>
                          <TableCell align={ requestForReview.status === 'Requested' || requestForReview.status === 'Awaiting Additional Review' ? 'center': 'left'}>
                            Quantity
                          </TableCell>
                          {(requestForReview.status === 'Requested' || requestForReview.status === 'Awaiting Additional Review') && (
                            <TableCell></TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {requestEmployee?.requestItems.map((requestItem, requestItemIndex) => {
                          if (!requestItem.isAdminAdded) {
                            return (
                              <TableRow key={`${requestEmployeeIndex}-request-item-${requestItemIndex}`}>
                                <TableCell>{requestItem.isStandard ? 'Standard' : 'Non-Standard'}</TableCell>
                                <TableCell>{requestItem.item}</TableCell>
                                <TableCell>{requestItem.type}</TableCell>
                                <TableCell>{requestItem.size}</TableCell>
                                <TableCell>{requestItem.color}</TableCell>
                                <TableCell>{requestItem.make}</TableCell>
                                <TableCell>{requestItem.model}</TableCell>
                                {requestForReview.status === 'Requested' || requestForReview.status === 'Awaiting Additional Review' ? (
                                  <TableCell align="center">
                                    <Button disableRipple size="small"
                                      startIcon={
                                        <IndeterminateCheckBoxIcon
                                          sx={{ m: { xs: 0, sm: 1 }, '&:hover': { color: 'secondary.main',}, color: 'green', }}
                                          onClick={() =>
                                            setRequest((prevRequest) => ({
                                              ...prevRequest,
                                              requestEmployees:
                                                prevRequest.requestEmployees.map(
                                                  (employee, empIndex) => {
                                                    if (empIndex === requestEmployeeIndex) {
                                                      return {
                                                        ...employee,
                                                        requestItems:
                                                          employee.requestItems.map(
                                                            (item, idx) => {
                                                              if (idx === requestItemIndex) {
                                                                if (!item.quantity)
                                                                  return item;
                                                                if (item.quantity === 1 && employee.requestItems.length > 1){
                                                                  removeItem(requestEmployeeIndex,requestItemIndex);
                                                                }
                                                                return {
                                                                  ...item,
                                                                  quantity: item.quantity > 1 ? item.quantity - 1 : 1,
                                                                };
                                                              }
                                                              return item;
                                                            }
                                                          ),
                                                      };
                                                    }
                                                    return employee;
                                                  }
                                                ),
                                            }))
                                          }
                                        />
                                      }
                                      endIcon={
                                        <AddBoxIcon 
                                          sx={{ m: { xs: 0, sm: 1 }, '&:hover': { color: 'secondary.main',}, color: 'green', }}
                                          onClick={() =>
                                            setRequest((prevRequest) => ({
                                              ...prevRequest,
                                              requestEmployees:
                                                prevRequest.requestEmployees.map(
                                                  (employee, empIndex) => {
                                                    if (empIndex === requestEmployeeIndex) {
                                                      return {
                                                        ...employee,
                                                        requestItems:
                                                          employee.requestItems.map(
                                                            (item, idx) => {
                                                              if (idx === requestItemIndex) {
                                                                if (!item.quantity){
                                                                  return item;
                                                                }
                                                                return {
                                                                  ...item,
                                                                  quantity: item.quantity + 1,
                                                                };
                                                              }
                                                              return item;
                                                            }
                                                          ),
                                                      };
                                                    }
                                                    return employee;
                                                  }
                                                ),
                                            }))
                                          }
                                        />
                                      }
                                      sx={{ color: 'black', border: 'none', '&:hover': { bgcolor: 'transparent', }, }}>
                                      {formatQuantity(requestItem.quantity, requestItem.units)}
                                    </Button>
                                  </TableCell>
                                ) : (
                                  <TableCell>{formatQuantity(requestItem.quantity,requestItem.units)}</TableCell>
                                )}

                                {(requestForReview.status === 'Requested' || requestForReview.status === 'Awaiting Additional Review') && (
                                  <TableCell align="center">
                                    <IconButton
                                      aria-label="delete"
                                      size="large"
                                      color="primary"
                                      onClick={() => removeItem(requestEmployeeIndex, requestItemIndex)}
                                      disabled={requestEmployee.requestItems.length === 1}
                                    >
                                      <CancelIcon />
                                    </IconButton>
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          }
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {(requestForReview.status === 'Requested' || requestForReview.status === 'Awaiting Additional Review') && (
                    <Box sx={{ m: 2, bgcolor: 'secondary.dark', p: 1 }}>
                      {/* add item button */}
                      <Box display="flex" justifyContent="left" alignItems="center" m={1}>
                        <Button onClick={() => addItem(requestEmployeeIndex)} startIcon={<AddIcon />}>
                          Add Equipment
                        </Button>
                      </Box>
                      {requestEmployee.requestItems.map((requestItem, requestItemIndex) => {
                        if (requestItem.isAdminAdded) {
                          return (
                            <Grid container spacing={2} columns={{ md: 66 }} key={requestItemIndex} m={1}>
                              {/* item detail fields */}
                              <Grid item xs md={60}>
                                <Grid container spacing={2}>
                                  <RequestDropdown
                                    label="Equipment"
                                    onChange={(event: SelectChangeEvent) =>
                                      setRequest((prevRequest) => ({
                                        ...prevRequest,
                                        requestEmployees:
                                          prevRequest.requestEmployees.map(
                                            (employee, empIndex) => {
                                              if (empIndex === requestEmployeeIndex) {
                                                return {
                                                  ...employee,
                                                  requestItems:
                                                    employee.requestItems.map(
                                                      (item, idx) => {
                                                        if (idx === requestItemIndex) {
                                                          const isStandard = event.target.value !== 'Other Non-Standard Equipment';
                                                          return {
                                                            category: event.target.value,
                                                            isStandard: isStandard,
                                                            item: isStandard ? 
                                                              event.target.value : 
                                                              '',
                                                              type: '',
                                                              color: '',
                                                              size: '',
                                                              make: '',
                                                              model: '',
                                                              quantity: undefined,
                                                              isAdminAdded: true,
                                                          };
                                                        }
                                                        return item;
                                                      }
                                                    ),
                                                };
                                              }
                                              return employee;
                                            }
                                          ),
                                      }))
                                    }
                                    value={requestItem.category}
                                    options={ppeItems.map((i) => i.category)}
                                    required
                                  />

                                  {ppeItems.find((i) => i.category === requestItem.category)?.dropdownFields?.map((fieldItem) => (
                                    <RequestDropdown
                                      key={fieldItem.field}
                                      label={fieldItem.label}
                                      onChange={(event: SelectChangeEvent) =>
                                        setRequest((prevRequest) => ({
                                          ...prevRequest,
                                          requestEmployees:
                                            prevRequest.requestEmployees.map(
                                              (employee, empIndex) => {
                                                if (empIndex === requestEmployeeIndex) {
                                                  return {
                                                    ...employee,
                                                    requestItems:
                                                      employee.requestItems.map(
                                                        (item, idx) => {
                                                          if (idx === requestItemIndex) {
                                                            return {
                                                              ...item,
                                                              [fieldItem.field]: event.target.value,
                                                            };
                                                          }
                                                          return item;
                                                        }
                                                      ),
                                                  };
                                                }
                                                return employee;
                                              }
                                            ),
                                        }))
                                      }
                                      value={requestItem[fieldItem.field as keyof IRequestItem]}
                                      options={(ppeItems.find((i) => i.category === requestItem.category) as any)?.[fieldItem.field] || [] }
                                      required
                                    />
                                    ))}
                                  {ppeItems.find((i) => i.category === requestItem.category)?.textFields?.map((fieldItem) => (
                                    <RequestTextField
                                      key={fieldItem.field}
                                      label={fieldItem.label}
                                      onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                      ) =>
                                        setRequest((prevRequest) => ({
                                          ...prevRequest,
                                          requestEmployees:
                                            prevRequest.requestEmployees.map(
                                              (employee, empIndex) => {
                                                if (empIndex === requestEmployeeIndex) {
                                                  return {
                                                    ...employee,
                                                    requestItems: employee.requestItems.map(
                                                      (item, idx) => {
                                                        if (idx === requestItemIndex) {
                                                          return {
                                                            ...item,
                                                            [fieldItem.field]: event.target.value,
                                                          };
                                                        }
                                                        return item;
                                                      }
                                                    ),
                                                  };
                                                }
                                                return employee;
                                              }
                                            ),
                                        }))
                                      }
                                      value={requestItem[fieldItem.field as keyof IRequestItem] || ''}
                                      type={fieldItem.field === 'quantity'? 'number' : 'text'}
                                      required
                                    />
                                  ))}
                                </Grid>
                              </Grid>
                              {/* remove item button */}
                              <Grid item xs md={6}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2, }}>
                                  <IconButton aria-label="delete" size="large" color="primary" onClick={() => removeItem(requestEmployeeIndex, requestItemIndex)}>
                                    <CancelIcon />
                                  </IconButton>
                                </Box>
                              </Grid>
                            </Grid>
                          );
                        }
                      })}
                    </Box>
                  )}

                  {(requestForReview.status === 'Requested' || requestForReview.status === 'Awaiting Additional Review') && (
                    <Box m={2}>
                      <TextField
                        label="Comments"
                        multiline
                        fullWidth
                        variant="standard"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          setRequest((prevRequest) => ({
                            ...prevRequest,
                            requestEmployees: prevRequest.requestEmployees.map(
                              (employee, index) => {
                                if (index === requestEmployeeIndex) {
                                  return {
                                    ...employee,
                                    comments: event.target.value,
                                  };
                                }
                                return employee;
                              }
                            ),
                          }))
                        }
                        value={requestEmployee.comments ?? ''}
                      />
                    </Box>
                  )}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 2, }}>
                  {requestForReview.status === 'Awaiting Additional Review' && requestForReview.requestEmployees[requestEmployeeIndex]?.status ==='Additional Review' && (
                    <Button
                      variant="contained"
                      size="small"
                      style={{ marginLeft: 16 }}
                      sx={{ bgcolor: `statusAdditionalReview.light`, color: `statusAdditionalReview.main`, borderColor: `statusAdditionalReview.main`, '&:hover': {   bgcolor: `statusAdditionalReview.dark`, },}}
                    >
                      Additional Review
                    </Button>
                  )}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                  {requestForReview.status === 'Requested' || (requestForReview.status === 'Awaiting Additional Review' && requestForReview.requestEmployees[requestEmployeeIndex]?.status === 'Additional Review') ? (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Reject</Typography>
                      <Switch
                        defaultChecked
                        sx={{'&.MuiSwitch-root .MuiSwitch-switchBase': { color: 'red', }, '&.MuiSwitch-root .Mui-checked': { color: 'green', }, }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setRequest((prevRequest) => ({
                            ...prevRequest,
                            requestEmployees: prevRequest.requestEmployees.map(
                              (employee, index) => {
                                if (index === requestEmployeeIndex) {
                                  return {
                                    ...employee,
                                    status: event.target.checked ? 'Approved' : 'Rejected',
                                  };
                                }
                                return employee;
                              }
                            ),
                          }));
                        }}
                      />
                      <Typography>Approve</Typography>
                    </Stack>
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      style={{ marginLeft: 16 }}
                      sx={{
                        bgcolor: `status${requestEmployee.status.replaceAll(' ', '')}.light`,
                        color: `status${requestEmployee.status.replaceAll(' ', '')}.main`,
                        borderColor: `status${requestEmployee.status.replaceAll(' ','' )}.main`, 
                        '&:hover': { bgcolor: `status${requestEmployee.status.replaceAll(' ', '')}.dark`,},
                      }}
                    >
                      {requestEmployee.status}
                    </Button>
                  )}
                </Box>
                <Box m={2} pb={2}>
                  <Card>
                    <CardHeader title="Extra Controls" sx={{ bgcolor: 'primary.main', color: 'white' }} titleTypographyProps={{variant: 'subtitle2'}} />
                    <CardContent>
                      <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <LoadingButton
                          variant="contained"
                          fullWidth
                          onClick={() => handleVoidRequestEmployee(requestEmployee)}
                          startIcon={<BlockIcon color="error" />}
                          disabled={ requestEmployee.status === 'Void'}
                          loading={voidRequestEmployeeLoading}
                        >
                          Void Employee Request
                        </LoadingButton>
                      </Grid>
                      <Grid container item xs={8} spacing={2}>
                        <Grid item xs={12}>
                          {newInternalComments.length > 0 &&
                            <TextField
                              id={`employee-request-${requestEmployeeIndex}-internal-comment-textarea`}
                              label="Internal Comment"
                              multiline
                              fullWidth
                              rows={2}
                              value={newInternalComments[requestEmployeeIndex].message}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                let newComment: CommentFormValues = {
                                  ...newInternalComments[requestEmployeeIndex],
                                  message: event.target.value,
                                };
                                let tempArray = [...newInternalComments];
                                tempArray[requestEmployeeIndex] = newComment;
                                setNewInternalComments(tempArray);
                              }}
                            />
                          }
                        </Grid>
                        <Grid item xs={12}>
                          <LoadingButton
                            variant="contained"
                            fullWidth
                            onClick={() => submitInternalComment(requestEmployee.id!, requestEmployeeIndex)}
                            loading={createInternalCommentLoading}
                            disabled={newInternalComments[requestEmployeeIndex] === undefined || newInternalComments[requestEmployeeIndex].message.length < 1}
                          >
                            Submit Internal Comment
                          </LoadingButton>
                      </Grid>
                      </Grid>
                      {(requestEmployee.internalComments !== null && requestEmployee.internalComments!.length > 0) &&
                      <Grid item xs={12}>
                        <TableContainer component={Paper} elevation={1} key={`${requestEmployee.id}-comment-table`}>
                          <Table>
                            <TableHead>
                              <TableRow sx={{ '& th': { backgroundColor: 'secondary.dark', },}} >
                                <TableCell>Comment</TableCell>
                                <TableCell align="right">Name</TableCell>
                                <TableCell align="right">Created</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {requestEmployee.internalComments?.map((comment) => {
                                return(
                                  <TableRow key={comment.id}>
                                    <TableCell>{comment.message}</TableCell>
                                    <TableCell align="right">{comment.createdByName}</TableCell>
                                    <TableCell align="right">{timestampLocalizer(comment.created!)}</TableCell>
                                  </TableRow>
                                )}
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      }
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>

        {/* submit button */}
        <Box display="flex" alignItems="center" justifyContent="center" mt={10} ml={2} mr={2}>
          {requestForReview.status === 'Requested' || requestForReview.status === 'Awaiting Additional Review' ? (
            <LoadingButton variant="contained" type="submit" size="large" loading={submitLoading}>
              Submit
            </LoadingButton>
          ) : (
            <Alert severity="info">
              This request has been reviewed and processed.
            </Alert>
          )}
        </Box>
        
        {/* Space filler for footer top */}
        <Box height={128}></Box>
      </Box>
    </Box>
  );
};

export default RequestDetails;
