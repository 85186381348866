import { create } from 'zustand';

import Agent from 'app/api/agent';
import { IOrder, IOrderItemSum, IPayrollDeduction } from 'app/models/order';
import { AxiosResponse } from 'axios';

interface IOrderStore {
  /* fetch order by id */
  order?: IOrder;
  orderLoading: boolean;
  setOrder: (orderId: number) => void;

  /* fetch my order by id and email */
  myOrder?: IOrder;
  myOrderLoading: boolean;
  setMyOrder: (orderId: number, email: string, isAdmin: boolean) => void;

  /* fetch all orders */
  orderList: IOrder[];
  orderListLoading: boolean;
  setOrderList: () => void;

  /* fetch payrollDeductions */
  payrollDeductionList: IPayrollDeduction[];
  payrollDeductionListLoading: boolean;
  setPayrollDeductionList: () => void;

  /* fetch orders by email */
  ordersByEmail: IOrder[];
  ordersByEmailLoading: boolean;
  setOrdersByEmail: (email: string) => void;

  /* fetch orders in category */
  setOrderListInCategory: (catId: number) => void;

  /* fetch order statistics sum */
  orderItemSumList: IOrderItemSum[];
  orderItemSumListLoading: boolean;
  setOrderItemSumList: (catId: number) => void;

  /* submit new order */
  submitOrder: (order: IOrder) => Promise<void | AxiosResponse>;

  /* submit new PPE order */
  submitPpeOrder: (order: IOrder) => Promise<void | AxiosResponse>;

  /* submit new pickup */
  submitPickup: (order: IOrder) => Promise<void | AxiosResponse>;

  /* modify order */
  modifyOrder: (order: IOrder) => Promise<void | AxiosResponse>;

  /* receive order */
  receiveOrder: (order: IOrder) => Promise<void | AxiosResponse>;

  /* ship order */
  shipOrder: (order: IOrder) => Promise<void | AxiosResponse>;

  /* return order */
  returnOrder: (order: IOrder) => Promise<void | AxiosResponse>;

  /* approve order */
  approveOrder: (order: IOrder) => Promise<void | AxiosResponse>;

  /* reject order */
  rejectOrder: (order: IOrder) => Promise<void | AxiosResponse>;

}

export const useOrderStore = create<IOrderStore>((set, get) => ({
  /* fetch order by id */
  orderLoading: false,
  setOrder: (orderId) => {
    set({ orderLoading: true });
    Agent.Order.loadOrder(orderId)
      .then((response) => {
        set({
          order: response,
          orderLoading: false,
        });
      })
      .catch(() => set({ orderLoading: false }));
  },

  /* fetch my order by id and email */
  myOrderLoading: false,
  setMyOrder: (orderId, email, isAdmin) => {
    set({ myOrderLoading: true });
    Agent.Order.loadMyOrder(orderId, email, isAdmin)
      .then((response) => {
        set({
          myOrder: response,
          myOrderLoading: false,
        });
      })
      .catch(() => set({ myOrderLoading: false }));
  },

  /* fetch all orders */
  orderList: [],
  orderListLoading: false,
  setOrderList: () => {
    set({ orderListLoading: true });
    Agent.Order.loadAllOrders()
      .then((response) => {
        set({
          orderList: response,
          orderListLoading: false,
        });
      })
      .catch(() => set({ orderListLoading: false }));
  },

  /* fetch payrollDeductions */
  payrollDeductionList: [],
  payrollDeductionListLoading: false,
  setPayrollDeductionList: () => {
    set({ payrollDeductionListLoading: true });
    Agent.Order.loadPayrollDeductions()
      .then((response) => {
        set({
          payrollDeductionList: response,
          payrollDeductionListLoading: false,
        });
      })
      .catch(() => set({ payrollDeductionListLoading: false }));
  },

  /* fetch orders in category */
  setOrderListInCategory: (catId) => {
    set({ orderListLoading: true });
    Agent.Order.loadOrdersInCategory(catId)
      .then((response) => {
        set({
          orderList: response,
          orderListLoading: false,
        });
      })
      .catch(() => set({ orderListLoading: false }));
  },

  /* fetch order statistics sum */
  orderItemSumList: [],
  orderItemSumListLoading: false,
  setOrderItemSumList: (catId) => {
    set({ orderItemSumListLoading: true });
    Agent.Order.countOrderItem(catId)
      .then((response) => {
        set({
          orderItemSumList: response,
          orderItemSumListLoading: false,
        });
      })
      .catch(() => set({ orderItemSumListLoading: false }));
  },

  /* fetch orders by email */
  ordersByEmail: [],
  ordersByEmailLoading: false,
  setOrdersByEmail: (email) => {
    set({ ordersByEmailLoading: true });
    Agent.Order.loadOrdersByEmail(email)
      .then((response) => {
        set({
          ordersByEmail: response,
          ordersByEmailLoading: false,
        });
      })
      .catch(() => set({ ordersByEmailLoading: false }));
  },

  /* submit new order */
  submitOrder: async (order) => {
    return Agent.Order.createOrder(order)
      .then((response) => response)
      .catch((error) => console.log(error));
  },

  /* submit new PPE order */
  submitPpeOrder: async (order) => {
    return Agent.Order.createPpeOrder(order)
      .then((response) => response)
      .catch((error) => console.log(error));
  },

  /* submit new pickup */
  submitPickup: async (order) => {
    return Agent.Order.createPickup(order)
      .then((response) => response)
      .catch((error) => console.log(error));
  },

  /* modify order */
  modifyOrder: async (order) => {
    return Agent.Order.modifyOrder(order)
      .then((response) => response)
      .catch((error) => console.log(error));
  },

  /* receive order */
  receiveOrder: async (order) => {
    return Agent.Order.receiveOrder(order)
      .then((response) => response)
      .catch((error) => console.log(error));
  },
  /* ship order */
  shipOrder: async (order) => {
    return Agent.Order.shipOrder(order)
      .then((response) => response)
      .catch((error) => console.log(error));
  },
  /* return order */
  returnOrder: async (order) => {
    return Agent.Order.returnOrder(order)
      .then((response) => response)
      .catch((error) => console.log(error));
  },

  /*approve order */
  approveOrder: async (order) => {
    return Agent.Order.approveOrder(order)
      .then((response) => response)
      .catch((error) => console.log(error));
  },

  /* reject order */
  rejectOrder: async (order) => {
    return Agent.Order.rejectOrder(order)
      .then((response) => response)
      .catch((error) => console.log(error));
  },

}));
