import React, { useEffect, useRef, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import {
  LoadingButton,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Chip,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Alert,
  CardContent,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { CommentFormValues, IRequest, IRequestEmployee } from 'app/models/ppeRequest/request';
import { useModalStore } from 'app/stores/modalStore';
import { useRequestStore } from 'app/stores/requestStore';
import { useUserStore } from 'app/stores/userStore';
import { useNavigate, useParams } from 'react-router-dom';
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint from 'react-to-print';
import AddBoxIcon from '@mui/icons-material/AddBox';
import BlockIcon from '@mui/icons-material/Block';
import CancelIcon from '@mui/icons-material/Cancel';
import GradeIcon from '@mui/icons-material/Grade';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import Loading from 'app/common/modals/Loading';
import NotFound from 'app/common/modals/NotFound';
import RequestHeader from './RequestHeader';
import RequestDetailsTable from './RequestDetailsTable';
import { timestampLocalizer } from 'app/common/util/util';

const RequestOrderDetails = () => {
  /* State and functions from custom hooks */
  const { requestPageSize, requestPage, requestId }: any = useParams();
  const {
    requestForReview,
    requestForReviewLoading,
    setRequestForReview,
    shipRequestOrder,
    voidRequestEmployee,
    voidRequestEmployeeLoading,
    createInternalComment,
    createInternalCommentLoading
  } = useRequestStore();
  const { 
    requestOrderShippedOpen, 
    setRequestOrderShippedOpen 
  } = useModalStore();
  const { user } = useUserStore();
  const { employeeList, setEmployeeList } = useUserStore();
  const { setSnackBar } = useModalStore();
  const { accounts } = useMsal();
  
  const navigate = useNavigate();
  const componentRef = useRef(null);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  /* Define component state variables */
  const [request, setRequest] = useState<IRequest>({} as IRequest);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [newInternalComments, setNewInternalComments] = useState([] as CommentFormValues[]);
  
  const options = ['', 'Shipped', 'Additional Review'];

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const handleVoidRequestEmployee = (requestEmployee: IRequestEmployee) => {
    let updatedRequestEmployee: IRequestEmployee = {
      ...requestEmployee,
      status: 'Void',
      isReassessed: true,
      reassessedBy: user?.displayName!
    };
    voidRequestEmployee(updatedRequestEmployee)
    .then((response) => {
      if (response?.status === 200) {
        setRequestForReview(requestId);
         setSnackBar({
           open: true,
           type: 'success',
           content: `Employee Request has been marked void.`,
         });
       } else {
         setSnackBar({
           open: true,
           type: 'error',
           content: `Unable to void Employee Request.`,
         });
       }
     })
     .catch((error) => console.log(error));
  };

  const submitInternalComment = (requestEmployeeId: number, requestEmployeeIndex: number) => {
    if(requestForReview && newInternalComments[requestEmployeeIndex].message !== ''){
      newInternalComments[requestEmployeeIndex].createdByName = user?.displayName!;
      newInternalComments[requestEmployeeIndex].createdByEmail = user?.userPrincipalName!;
      createInternalComment(requestEmployeeId, newInternalComments[requestEmployeeIndex])
        .then((response) => {
          if (response?.status === 200) {
            setRequestForReview(requestId);
            setSnackBar({
              open: true,
              type: 'success',
              content: `Internal Comment added to Employee Request.`,
            });
          } else {
            setSnackBar({
              open: true,
              type: 'error',
              content: `Unable to add Internal Comment.`,
            });
          }
        })
        .catch((error) => console.log(error));
    };
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setSubmitLoading(true);

    if (request) {
      shipRequestOrder(request)
        .then((response) => {
          if (response?.status === 200) {
            setRequestOrderShippedOpen(true);
          } else {
            setSnackBar({
              open: true,
              type: 'error',
              content: `Request Order submitting is not completed.`,
            });
          }
          setSubmitLoading(false);
        })
        .catch((error) => console.log(error));
    }
  };

  /* Fetch request details on component mount */
  useEffect(() => {
    setRequestForReview(requestId);
    setEmployeeList();
  }, [requestId, setRequestForReview, setEmployeeList]);

  /* Update request details when fetched */
  useEffect(() => {
    if (requestForReview && employeeList) {
      const updatedRequest = {
        ...requestForReview,
        requestEmployees: requestForReview.requestEmployees.map((e) => {
          let matchingEmail;
          if (e.email === undefined || e.email === null || e.email === '') {
            matchingEmail = employeeList.find((employee) => e.employeeNumber === employee.employeeNumber)?.email;
          } else {
            matchingEmail = e.email;
          }

          return {
            ...e,
            requestItems: e.requestItems.map((i) => {
              if (i.shippedQuantity !== undefined && i.shippedQuantity !== null) {
                return i;
              } else {
                return { ...i, shippedQuantity: i.quantity };
              }
            }),
            email: matchingEmail,
          };
        }),
      };
      setRequest(updatedRequest);
      if(requestForReview.requestEmployees.length > 0){
        let tempArray = [] as CommentFormValues[];
        requestForReview.requestEmployees.forEach(() => {
          tempArray.push(new CommentFormValues());
        });
        setNewInternalComments(tempArray)
      }
    }
  }, [requestForReview, employeeList]);

  /* Render loading state while request details are loading */
  /* Render not found state if request details are not available */
  if (requestForReviewLoading || Object.keys(request).length === 0) {
    return <Loading />;
  } else if (!requestForReview) {
    return <NotFound />;
  }

  return (
    <Box mx={2} mt={2} component="form" onSubmit={handleSubmit}>
      {/* Buttons for navigation and printing */}
      <Box m={2} display="flex" justifyContent="space-between" alignItems="center">
        <Button
          variant="contained"
          size="small"
          startIcon={<KeyboardReturnIcon />}
          onClick={() => {
            navigate(
              `/admin/ppeRequestOrder/${requestPageSize}/${requestPage}`
            );
          }}
        >
          Back to table
        </Button>
        <ReactToPrint
          content={() => componentRef.current}
          trigger={() => (
            <Button variant="contained" size="small" startIcon={<PrintIcon />}>
              Print Request
            </Button>
          )}
        />
      </Box>
      <Box ref={componentRef}>
        {/* Request header */}
        <Box sx={{'@media print': { display: 'none', }, }}>
          <RequestHeader ppeRequest={requestForReview} />
          <RequestDetailsTable ppeRequest={requestForReview} />
        </Box>
        <Box sx={{ position: 'fixed', bottom: '0px', width: '100%', background: 'transparent', display: 'none', fontSize: '10px', '@media print': { display: 'block', }, }}>
          Request ID # : {request?.id}
        </Box>
        {/* Request details table for each employee */}
        <Box mt={2}>
          {request?.requestEmployees?.map((requestEmployee, requestEmployeeIndex) => (
            <Box key={requestEmployee.id} component={Paper} my={2}>
              {/* print page break */}
              <Box sx={{ '@media print': { display: 'block',   pageBreakBefore: 'always', },display: 'none',}}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'white', margin: 2,}} className="print-content">
                  <Box sx={{display: 'flex',alignItems: 'center',justifyContent: 'center', }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                      Request ID # : {request?.id}
                    </Typography>
                    {request.newHire && (
                      <Button variant="text" startIcon={<GradeIcon />} color="secondary" sx={{ m: 1 }}>
                        New Hire
                      </Button>
                    )}
                  </Box>
                  <Button
                    variant="contained"
                    size="small"
                    style={{ marginLeft: 16 }}
                    sx={{
                      bgcolor: `status${request?.status?.replaceAll(' ','')}.light`,
                      color: `status${request?.status?.replaceAll(' ','' )}.main`,
                      '&:hover': {
                        bgcolor: `status${request?.status?.replaceAll(' ','')}.dark`,
                      },
                    }}
                  >
                    {request?.status}
                  </Button>
                </Toolbar>
                <TableContainer component={Paper} elevation={3}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{'& th': { backgroundColor: 'secondary.dark',},}}>
                        <TableCell>Request Date</TableCell>
                        <TableCell>Requester</TableCell>
                        <TableCell>Requester Email</TableCell>
                        <TableCell>Discipline</TableCell>
                        <TableCell>DSC</TableCell>
                        <TableCell>Reviewer</TableCell>
                        <TableCell>Reviewed Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {new Date(request?.createdTime as Date).toLocaleDateString()}
                        </TableCell>
                        <TableCell>{request?.requester}</TableCell>
                        <TableCell>{request?.requesterEmail}</TableCell>
                        <TableCell>{request?.discipline}</TableCell>
                        <TableCell>{request?.dsc}</TableCell>
                        <TableCell>{request?.reviewer}</TableCell>
                        {request?.reviewed && (
                          <TableCell>
                            {new Date(request.reviewed as Date).toLocaleDateString()}
                          </TableCell>
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                  <Chip label={requestEmployeeIndex + 1} sx={{ m: 1 }} />
                </Box>
                <TableContainer component={Paper} elevation={2}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{'& th': { color: 'white', backgroundColor: 'primary.main',}, }}>
                        <TableCell>Employee Number</TableCell>
                        <TableCell>Recipient Name</TableCell>
                        <TableCell>Co Brand</TableCell>
                        <TableCell>Office</TableCell>
                        <TableCell>Ship To</TableCell>
                        <TableCell>Training Completed</TableCell>
                        {request.newHire && <TableCell>WorkFit</TableCell>}
                        <TableCell>Project No</TableCell>
                        <TableCell>Task</TableCell>
                        <TableCell>Org</TableCell>
                        <TableCell>Comments</TableCell>
                        {(requestEmployee?.status === 'Approved' || requestEmployee?.status === 'Additional Review') && (
                          <TableCell>Shipper</TableCell>
                        )}
                        {(requestEmployee?.status === 'Approved' || requestEmployee?.status === 'Additional Review') && (
                          <TableCell>Shipped Date</TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{requestEmployee.employeeNumber}</TableCell>
                        <TableCell>{requestEmployee.name}</TableCell>
                        <TableCell>{requestEmployee.coBrand}</TableCell>
                        <TableCell>{requestEmployee.office}</TableCell>
                        <TableCell>{requestEmployee.shipTo}</TableCell>
                        <TableCell>{requestEmployee.trainingCompleted ? 'Yes' : 'No'}</TableCell>
                        {request.newHire && (<TableCell>{requestEmployee.workFit ? 'Yes' : 'No'}</TableCell>)}
                        <TableCell>{requestEmployee.projectNo}</TableCell>
                        <TableCell>{requestEmployee.task}</TableCell>
                        <TableCell>{requestEmployee.org}</TableCell>
                        <TableCell>{requestEmployee.comments}</TableCell>
                        {(requestEmployee?.status === 'Approved' || requestEmployee?.status === 'Additional Review') && (
                          <TableCell width={120}>{requestEmployee.shipper}</TableCell>
                        )}
                        {(requestEmployee?.status === 'Approved' || requestEmployee?.status === 'Additional Review') && requestEmployee.shippedTime && (
                          <TableCell>{new Date(requestEmployee.shippedTime as Date).toLocaleDateString()}</TableCell>
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* Request details table for each item */}
                <Box m={2}>
                  <TableContainer component={Paper} elevation={1} key={requestEmployee.id}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow sx={{' & th': { backgroundColor: 'secondary.dark', },}}>
                          <TableCell>Category</TableCell>
                          <TableCell>Item</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Size</TableCell>
                          <TableCell>Color</TableCell>
                          <TableCell>Make</TableCell>
                          <TableCell>Model</TableCell>
                          <TableCell align="center">Requested Quantity</TableCell>
                          {(requestEmployee?.status === 'Approved' ||  requestEmployee?.status === 'Additional Review') && (
                            <TableCell align="center">Shipped Quantity</TableCell>
                          )}
                          {(requestEmployee?.status === 'Approved' || requestEmployee?.status === 'Additional Review') && (
                            <TableCell></TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {requestEmployee?.requestItems?.filter((item) => item.shippedQuantity !== 0).map((item, itemIndex) => (
                          <TableRow key={itemIndex}>
                            <TableCell>{item.isStandard ? 'Standard' : 'Non-Standard'}</TableCell>
                            <TableCell>{item.item}</TableCell>
                            <TableCell>{item.type}</TableCell>
                            <TableCell>{item.size}</TableCell>
                            <TableCell>{item.color}</TableCell>
                            <TableCell>{item.make}</TableCell>
                            <TableCell>{item.model}</TableCell>
                            <TableCell align="center">{item.quantity}</TableCell>
                            {requestEmployee?.status === 'Approved' &&
                              (requestEmployee.isOrderShipped ? (
                                <TableCell align="center">{item.shippedQuantity}</TableCell>
                              ) : (
                                <TableCell align="center">
                                  <Button
                                    disableRipple
                                    size="small"
                                    startIcon={
                                      <IndeterminateCheckBoxIcon
                                        sx={{ m: { xs: 0, sm: 1 }, '&:hover': { color: 'secondary.main',}, color: 'green', }}
                                        onClick={() =>
                                          setRequest((prevRequest) => ({
                                            ...prevRequest,
                                            requestEmployees:
                                              prevRequest.requestEmployees.map((employee, empIndex) => {
                                                  if (empIndex === requestEmployeeIndex) {
                                                    return {
                                                      ...employee,
                                                      requestItems:
                                                        employee.requestItems.map(
                                                          (item, idx) => {
                                                            if (idx === itemIndex) {
                                                              if (!item.shippedQuantity){
                                                                return item;
                                                              }
                                                              if (item.shippedQuantity === 1 && employee.requestItems.filter((i) => i.shippedQuantity !== 0).length > 1) {
                                                                return {
                                                                  ...item,
                                                                  shippedQuantity: 0,
                                                                };
                                                              }
                                                              return {
                                                                ...item,
                                                                shippedQuantity:
                                                                  item.shippedQuantity > 1 ? item.shippedQuantity - 1 : 1,
                                                              };
                                                            }
                                                            return item;
                                                          }
                                                        ),
                                                    };
                                                  }
                                                  return employee;
                                                }
                                              ),
                                          }))
                                        }
                                      />
                                    }
                                    endIcon={
                                      <AddBoxIcon
                                        sx={{ m: { xs: 0, sm: 1 }, '&:hover': { color: 'secondary.main',}, color: 'green',}}
                                        onClick={() =>
                                          setRequest((prevRequest) => ({
                                            ...prevRequest,
                                            requestEmployees:
                                              prevRequest.requestEmployees.map(
                                                (employee, empIndex) => {
                                                  if (empIndex === requestEmployeeIndex) {
                                                    return {
                                                      ...employee,
                                                      requestItems: employee.requestItems.map((item, idx) => {
                                                        if (idx === itemIndex) {
                                                          if (!item.shippedQuantity){
                                                            return item;
                                                          }
                                                          return {
                                                            ...item,
                                                            shippedQuantity: item.shippedQuantity + 1,
                                                          };
                                                        }
                                                        return item;
                                                      }),
                                                    };
                                                  }
                                                  return employee;
                                                }
                                              ),
                                          }))
                                        }
                                      />
                                    }
                                    sx={{ color: 'black', border: 'none', '&:hover': { bgcolor: 'transparent', }, }}
                                  >
                                    {item.shippedQuantity ?? item.quantity}
                                  </Button>
                                </TableCell>
                              ))}
                            {requestEmployee?.status === 'Approved' &&
                              (!requestEmployee.isOrderShipped ? (
                                <TableCell align="center" width={80}>
                                  <IconButton
                                    aria-label="delete"
                                    size="large"
                                    color="primary"
                                    onClick={() =>
                                      setRequest((prevRequest) => ({
                                        ...prevRequest,
                                        requestEmployees:
                                          prevRequest.requestEmployees.map(
                                            (employee, empIndex) => {
                                              if (empIndex === requestEmployeeIndex) {
                                                return {
                                                  ...employee,
                                                  requestItems:
                                                    employee.requestItems.map((item, idx) => {
                                                        if (idx === itemIndex) {
                                                          if (!item.shippedQuantity){
                                                            return item;
                                                          }
                                                          return {
                                                            ...item,
                                                            shippedQuantity: 0,
                                                          };
                                                        }
                                                        return item;
                                                      }
                                                    ),
                                                };
                                              }
                                              return employee;
                                            }
                                          ),
                                      }))
                                    }
                                    disabled={requestEmployee.requestItems.filter((i) => i.shippedQuantity !== 0).length === 1}
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                </TableCell>
                              ) : (
                                <TableCell width={80}></TableCell>
                              ))}
                            {requestEmployee?.status === 'Additional Review' && (
                              <>
                                <TableCell></TableCell>
                                <TableCell width={80}></TableCell>
                              </>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                  <Timeline>
                    {requestForReview.requestEmployees[requestEmployeeIndex]?.status === 'Approved' && (
                      <>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot sx={{bgcolor: `status${requestForReview.requestEmployees[requestEmployeeIndex]?.status?.replaceAll(' ', '')}.light`,}}/>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Button
                              variant="contained"
                              size="small"
                              sx={{
                                ml: 2,
                                width: 150,
                                bgcolor: `status${requestForReview.requestEmployees[requestEmployeeIndex].status?.replaceAll(' ', '')}.light`,
                                color: `status${requestForReview.requestEmployees[requestEmployeeIndex].status?.replaceAll(' ', '')}.main`,
                                borderColor: `status${requestForReview.requestEmployees[requestEmployeeIndex].status?.replaceAll(' ', '')}.main`,
                                '&:hover': {
                                  bgcolor: `status${requestForReview.requestEmployees[requestEmployeeIndex].status?.replaceAll(' ', '')}.dark`,
                                },
                              }}
                            >
                              {requestForReview.requestEmployees[requestEmployeeIndex]?.status}
                            </Button>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot sx={{ bgcolor: requestEmployee.isOrderShipped || requestEmployee.isReassessed ? `statusShipped.main` : 'none',}} />
                          </TimelineSeparator>
                          <TimelineContent>
                            {requestForReview.requestEmployees[requestEmployeeIndex].isOrderShipped ? (
                              <Button
                                variant="contained"
                                size="small"
                                style={{ marginLeft: 16 }}
                                sx={{width: 150, bgcolor: `statusShipped.light`, color: `statusShipped.main`, borderColor: `statusShipped.main`,'&:hover': {bgcolor: `statusShipped.dark`, },}}
                              >
                                {'Shipped'}
                              </Button>
                            ) : (
                              <React.Fragment>
                                <ButtonGroup variant="contained" ref={anchorRef} aria-label="Button group with a nested menu" sx={{ ml: 2,}}>
                                  <Button
                                    onClick={handleClick}
                                    sx={{ width: 150, bgcolor: `statusShipped.light`, color: `statusShipped.main`, borderColor: `statusShipped.main`, '&:hover': {bgcolor: `statusShipped.dark`,},}}
                                    size="small"
                                  >
                                    {options[selectedIndex]}
                                  </Button>
                                  <Button
                                    size="small"
                                    aria-controls={open ? 'split-button-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-label="select merge strategy"
                                    aria-haspopup="menu"
                                    onClick={handleToggle}
                                    sx={{ bgcolor: `statusShipped.light`, color: `statusShipped.main`, borderColor: `statusShipped.main`, '&:hover': {bgcolor: `statusShipped.dark`, },}}
                                  >
                                    <ArrowDropDownIcon />
                                  </Button>
                                </ButtonGroup>
                                <Popper
                                  sx={{zIndex: 1,}}
                                  open={open}
                                  anchorEl={anchorRef.current}
                                  role={undefined}
                                  transition
                                  disablePortal
                                >
                                  {({ TransitionProps, placement }) => (
                                    <Grow {...TransitionProps} style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',}}>
                                      <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                          <MenuList id="split-button-menu" autoFocusItem>
                                            {options.map(
                                              (option, optionIndex) => (
                                                <MenuItem
                                                  key={option}
                                                  selected={optionIndex === selectedIndex}
                                                  onClick={(event) => {
                                                    setRequest(
                                                      (prevRequest) => ({
                                                        ...prevRequest,
                                                        requestEmployees:
                                                          prevRequest.requestEmployees.map((employee, index) => {
                                                              if (index === requestEmployeeIndex) {
                                                                return {
                                                                  ...employee,
                                                                  isOrderShipped: optionIndex === 1,
                                                                  shipper: optionIndex === 1 ? accounts[0].name : undefined,
                                                                  status: optionIndex === 2 ? 'Additional Review' : requestForReview.requestEmployees[requestEmployeeIndex]?.status,
                                                                  isReassessed: optionIndex === 2,
                                                                  reassessedBy: optionIndex === 2 ? accounts[0].name : employee.reassessedBy,
                                                                };
                                                              }
                                                              return employee;
                                                            }
                                                          ),
                                                      })
                                                    );
                                                    setSelectedIndex(optionIndex);
                                                    setOpen(false);
                                                  }}
                                                  sx={{ height: 36 }}
                                                >
                                                  {option}
                                                </MenuItem>
                                              )
                                            )}
                                          </MenuList>
                                        </ClickAwayListener>
                                      </Paper>
                                    </Grow>
                                  )}
                                </Popper>
                              </React.Fragment>
                            )}
                          </TimelineContent>
                        </TimelineItem>
                      </>
                    )}
                    {requestForReview.requestEmployees[requestEmployeeIndex]?.status === 'Rejected' || requestForReview.requestEmployees[requestEmployeeIndex]?.status === 'Void' && (
                      <TimelineItem>
                        <TimelineSeparator>
                          <TimelineDot sx={{bgcolor: `status${requestEmployee?.status?.replaceAll(' ','')}.light`,}} />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Button
                            variant="contained"
                            size="small"
                            sx={{
                              ml: 2,
                              width: 150,
                              bgcolor: `status${requestEmployee?.status?.replaceAll(' ','')}.light`,
                              color: `status${requestEmployee?.status?.replaceAll(' ','')}.main`,
                              borderColor: `status${requestEmployee?.status?.replaceAll(' ','')}.main`,
                              '&:hover': { bgcolor: `status${requestEmployee?.status?.replaceAll(' ','')}.dark`, },
                            }}
                          >
                            {requestEmployee?.status}
                          </Button>
                        </TimelineContent>
                      </TimelineItem>
                    )}
                    {requestForReview.requestEmployees[requestEmployeeIndex]?.status === 'Additional Review' && (
                      <>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot sx={{ bgcolor: `statusApproved.light`,}}/>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Button
                              variant="contained"
                              size="small"
                              sx={{
                                ml: 2,
                                width: 150,
                                bgcolor: `statusApproved.light`,
                                color: `statusApproved.main`,
                                borderColor: `statusApproved.main`,
                                '&:hover': {bgcolor: `statusApproved.dark`,},
                              }}
                            >
                              Approved
                            </Button>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot sx={{bgcolor: `status${requestEmployee?.status?.replaceAll(' ','')}.light`,}} />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Button
                              variant="contained"
                              size="small"
                              sx={{
                                ml: 2,
                                width: 150,
                                bgcolor: `status${requestEmployee?.status?.replaceAll(' ','')}.light`,
                                color: `status${requestEmployee?.status?.replaceAll(' ','')}.main`,
                                borderColor: `status${requestEmployee?.status?.replaceAll(' ','')}.main`,
                                '&:hover': { bgcolor: `status${requestEmployee?.status?.replaceAll(' ','')}.dark`,},
                              }}
                            >
                              Additional Review
                            </Button>
                          </TimelineContent>
                        </TimelineItem>
                      </>
                    )}
                  </Timeline>
                </Box>
                <Box m={2} pb={2}>
                  <Card>
                    <CardHeader title="Extra Controls" sx={{ bgcolor: 'primary.main', color: 'white' }} titleTypographyProps={{variant: 'subtitle2'}} />
                    <CardContent>
                      <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <LoadingButton
                          variant="contained"
                          fullWidth
                          onClick={() => handleVoidRequestEmployee(requestEmployee)}
                          startIcon={<BlockIcon color="error" />}
                          disabled={ requestEmployee.status === 'Void'}
                          loading={voidRequestEmployeeLoading}
                        >
                          Void Employee Request
                        </LoadingButton>
                      </Grid>
                      <Grid container item xs={8} spacing={2}>
                        <Grid item xs={12}>
                          {newInternalComments.length > 0 &&
                            <TextField
                              id={`employee-request-${requestEmployeeIndex}-internal-comment-textarea`}
                              label="Internal Comment"
                              multiline
                              fullWidth
                              rows={2}
                              value={newInternalComments[requestEmployeeIndex].message}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                let newComment: CommentFormValues = {
                                  ...newInternalComments[requestEmployeeIndex],
                                  message: event.target.value,
                                };
                                let tempArray = [...newInternalComments];
                                tempArray[requestEmployeeIndex] = newComment;
                                setNewInternalComments(tempArray);
                              }}
                            />
                          }
                        </Grid>
                        <Grid item xs={12}>
                          <LoadingButton
                            variant="contained"
                            fullWidth
                            onClick={() => submitInternalComment(requestEmployee.id!, requestEmployeeIndex)}
                            loading={createInternalCommentLoading}
                            disabled={newInternalComments[requestEmployeeIndex] === undefined || newInternalComments[requestEmployeeIndex].message.length < 1}
                          >
                            Submit Internal Comment
                          </LoadingButton>
                      </Grid>
                      </Grid>
                      {(requestEmployee.internalComments !== null && requestEmployee.internalComments!.length > 0) &&
                      <Grid item xs={12}>
                        <TableContainer component={Paper} elevation={1} key={`${requestEmployee.id}-comment-table`}>
                          <Table>
                            <TableHead>
                              <TableRow sx={{ '& th': { backgroundColor: 'secondary.dark', },}} >
                                <TableCell>Comment</TableCell>
                                <TableCell align="right">Name</TableCell>
                                <TableCell align="right">Created</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {requestEmployee.internalComments?.map((comment) => {
                                return(
                                  <TableRow key={comment.id}>
                                    <TableCell>{comment.message}</TableCell>
                                    <TableCell align="right">{comment.createdByName}</TableCell>
                                    <TableCell align="right">{timestampLocalizer(comment.created!)}</TableCell>
                                  </TableRow>
                                )}
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      }
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>

        <Box>
          <Dialog open={requestOrderShippedOpen} onClose={() => setRequestOrderShippedOpen(false)} scroll="body" fullWidth maxWidth="md">
            <DialogContent>
              <DialogContentText>Changes have been submitted.</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => { setRequestOrderShippedOpen(false); setRequestForReview(requestId);}}>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
        {/* submit button */}

        <Box display="flex" alignItems="center" justifyContent="center" mt={10} mx={2} sx={{'@media print': { display: 'none',},}}>
          {requestForReview.requestEmployees.some((e) => !e.isOrderShipped && e?.status === 'Approved') && (
            <LoadingButton variant="contained" type="submit" size="large" loading={submitLoading}>
              Submit
            </LoadingButton>
          )}
        </Box>

        {/* Space filler for footer top */}
        <Box height={128} sx={{'@media print': {  display: 'none',},}}></Box>
      </Box>
    </Box>
  );
};

export default RequestOrderDetails;
