import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  NativeSelect,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  ppeCoBrand,
  ppeItems,
  ppeTraining,
  unitOptions,
  workFit
} from 'app/common/options/ppeOptions';
import {
  IRequest,
  NewHireBundleRequestEmployeeFormValues,
  RequestEmployeeFormValues,
  RequestFormValues,
} from 'app/models/ppeRequest/request';
import { RequestItemFormValues } from 'app/models/ppeRequest/requestItem';
import { useMsal } from '@azure/msal-react';
import { LoadingButton } from '@mui/lab';
import { IEmployee } from 'app/models/user';
import { useModalStore } from 'app/stores/modalStore';
import { useRequestStore } from 'app/stores/requestStore';
import { useUserStore } from 'app/stores/userStore';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from 'app/common/modals/Loading';
import MessageContainer from 'app/common/modals/MessageContainer';
import RequestDropdown from './RequestDropdown';
import RequestTextField from './RequestTextField';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';


const PpeRequestForm = () => {
  /* State and functions from custom hooks */
  const { 
    employeeList, 
    employeeListLoading, 
    setEmployeeList, 
    adminUser } 
  = useUserStore();
  const {
    ppeManagers,
    setPpeManagers,
    submitRequest,
    homeDepartmentList,
    homeDepartmentListLoading,
    setHomeDepartmentList,
  } = useRequestStore();
  const { setSnackBar } = useModalStore();
  const { officeList, setOfficeList } = useUserStore();
  const { accounts } = useMsal();

  const [request, setRequest] = useState(new RequestFormValues());
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [showWorkFit, setShowWorkFit] = useState(false);

  
  const navigate = useNavigate();
  const location = useLocation();

  const addEmployee = () => {
    setRequest({
      ...request,
      requestEmployees: [
        ...request.requestEmployees,
        { ...new RequestEmployeeFormValues() },
      ],
    });
  };

  const removeEmployee = (employeeIndex: number) => {
    const updatedEmployees = [...request.requestEmployees];
    updatedEmployees.splice(employeeIndex, 1);
    setRequest({
      ...request,
      requestEmployees: updatedEmployees,
    });
  };

  const addItem = (employeeIndex: number) => {
    const updatedEmployees = [...request.requestEmployees];
    const updatedEmployee = { ...updatedEmployees[employeeIndex] };
    updatedEmployee.requestItems.push({ ...new RequestItemFormValues()});
    updatedEmployees[employeeIndex] = updatedEmployee;
    setRequest({
      ...request,
      requestEmployees: updatedEmployees,
    });
  };

  const removeItem = (employeeIndex: number, itemIndex: number) => {
    const updatedEmployees = [...request.requestEmployees];
    const updatedEmployee = { ...updatedEmployees[employeeIndex] };
    updatedEmployee.requestItems.splice(itemIndex, 1);
    updatedEmployees[employeeIndex] = updatedEmployee;
    setRequest({
      ...request,
      requestEmployees: updatedEmployees,
    });
  };

  const showTypeField = (category: string) => {
    if(category === '' || category === undefined){
      return false;
    }
    if(category !== 'Other Non-Standard Equipment'){
      return true;
    }
    return false;
  }

  const showSizeField = (category: string) => {
    if(category === 'Safety Vest' || category === 'Work Gloves'){
      return true;
    }
    return false;
  }

  const showColorField = (category: string) => {
    if(category === 'Safety Vest'){
      return true;
    }
    return false;
  }

  const showQuantityFields = (category: string) => {
    if(category === '' || category === undefined){
      return false;
    }
    return true;
  }

  const showNonStandardFields = (category: string) => {
    if(category === 'Other Non-Standard Equipment'){
      return true;
    }
    return false;
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    let formattedRequest: IRequest = {
      ...request,
      requester: accounts[0].name!,
      requesterEmail: accounts[0].username!,
    };

    const IsNotDSC: boolean = !ppeManagers.some((m) => 
      m.email.toLowerCase() === accounts[0]?.username.toLowerCase()) &&
      !homeDepartmentList.some((h) => h.disciplineSafetyCoordinatorsEmail.toLowerCase() === accounts[0].username.toLowerCase() ) &&
      adminUser.length === 0;

    if (IsNotDSC) {
      setSnackBar({
        open: true,
        type: 'warning',
        content: `Currently only DSC can submit PPE request.`,
      });
    } else {
      setSubmitLoading(true);
      submitRequest(formattedRequest)
        .then((response) => {
          if (response?.status === 200) {
            navigate('/request-confirmation', {
              state: { request: response?.data },
            });
          } else {
            setSnackBar({
              open: true,
              type: 'error',
              content: `Request submitting is not completed.`,
            });
          }
          setSubmitLoading(false);
        })
        .catch((error) => console.log(error));
    }
  };

  /* Fetch officeList, employeeList, ppeManagers on component mount */
  useEffect(() => {
    setOfficeList();
    setEmployeeList();
    setPpeManagers();
    setHomeDepartmentList();
    if(location.pathname.includes('newhire')){
      setShowWorkFit(true);
      setRequest({
        ...request,
        requestEmployees: [new NewHireBundleRequestEmployeeFormValues()],
      });
    }
  }, [setOfficeList, setEmployeeList, setPpeManagers, setHomeDepartmentList]);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      {/* header */}
      <Box m={2}>
        <MessageContainer icon={<HealthAndSafetyIcon />} message={showWorkFit ? 'H&S PPE Request - New Hire' : 'H&S PPE Request'} />
      </Box>
      {/* form */}
      <Box>
        {/* first section*/}
        <Grid container spacing={2}>
          {/* discipline field */}
          <Grid item xs={12} md={6} lg>
            <Box sx={{ m: 2 }}>
              <FormControl sx={{ minWidth: 120, width: '100%' }} size="medium" required>
                <InputLabel htmlFor="discipline-grouped-native-select" sx={{ '& .MuiFormLabel-asterisk': { color: 'warning.main' } }}>
                  Discipline
                </InputLabel>
                {homeDepartmentListLoading ? (
                  <Select id="discipline-grouped-native-select" label="Discipline">
                    <Loading />
                  </Select>
                ) : (
                  <Select
                    id="discipline-grouped-native-select"
                    label="Discipline"
                    native
                    onChange={(event: SelectChangeEvent) =>
                      setRequest((prevRequest) => ({
                        ...prevRequest,
                        discipline: event.target.value,
                      }))
                    }
                    value={request.discipline}
                  >
                    <option aria-label="None" value="" />
                    {homeDepartmentList.map((d) => (
                      <option value={d.homeDepartmentName} key={d.id}>
                        {d.homeDepartmentName}
                      </option>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Box>
          </Grid>

          {/* DSC field */}
          <Grid item xs={12} md={6} lg>
            <Box sx={{ m: 2 }}>
              <FormControl sx={{ minWidth: 120, width: '100%' }} size="medium" required >
                <InputLabel htmlFor="dsc-grouped-native-select" sx={{ '& .MuiFormLabel-asterisk': { color: 'warning.main' } }}>
                  Discipline Safety Coordinator
                </InputLabel>
                {homeDepartmentListLoading ? (
                  <Select id="dsc-grouped-native-select" label="Discipline Safety Coordinator">
                    <Loading />
                  </Select>
                ) : (
                  <Select
                    id="dsc-grouped-native-select"
                    label="Discipline Safety Coordinator"
                    native
                    onChange={(event: SelectChangeEvent) =>
                      setRequest((prevRequest) => {
                        const selectedHomeDepartment = homeDepartmentList.find((d) =>
                           d.disciplineSafetyCoordinatorsName === event.target.value
                        );
                        if (selectedHomeDepartment) {
                          return {
                            ...prevRequest,
                            dsc: selectedHomeDepartment.disciplineSafetyCoordinatorsName,
                            dscEmail: selectedHomeDepartment.disciplineSafetyCoordinatorsEmail,
                          };
                        }
                        return prevRequest;
                      })
                    }
                    value={request.dsc}
                  >
                    <option aria-label="None" value="" />
                    {Array.from(new Set(homeDepartmentList.map((d) => d.disciplineSafetyCoordinatorsName).sort())).map((d, index) => (
                      <option value={d} key={index}>{d}</option>
                    ))}
                  </Select>
                )}
                <FormHelperText>
                  <Typography variant="subtitle2" fontSize="small" color="primary" component={'span'}>
                    Must sign off before order is placed
                  </Typography>
                </FormHelperText>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* divider */}
      <Divider
        variant="middle"
        sx={{ marginTop: 2, marginBottom: 4, '&::before, &::after': {  borderColor: 'secondary.light', }, }}
      >
        <Chip label="PPE Equipment Request" />
      </Divider>
      {/* standard section */}
      {request.requestEmployees.map((employee, employeeIndex) => (
        <Box sx={{ m: 2, bgcolor: 'secondary.dark', p: 1 }} key={employeeIndex}>
          <Box display="flex" justifyContent="right" alignItems="center">
            <IconButton
              aria-label="delete"
              size="large"
              color="primary"
              onClick={() => removeEmployee(employeeIndex)}
              disabled={request.requestEmployees.length === 1}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
          <Box p={2}>
            <Box sx={{ display: { xs: 'block', md: 'flex' }, justifyContent: 'flex-start', alignItems: 'end', }} >
              {/* employee, co-brand, shipTo, trainingRequired fields */}
              <Grid container spacing={2}>
                <Grid item xs={12} lg>
                  <Box sx={{ m: 1 }}>
                    <Autocomplete
                      loading={employeeListLoading}
                      autoHighlight
                      value={employee.requestedEmployee}
                      onChange={(event, selectedEmployee: IEmployee | null) => {
                        setRequest((prevRequest) => {
                          if (selectedEmployee) {
                            return {
                              ...prevRequest,
                              requestEmployees: prevRequest.requestEmployees.map((employee, index) => {
                                if (index === employeeIndex) {
                                  return {
                                    ...employee,
                                    requestedEmployee: selectedEmployee,
                                    name: selectedEmployee.displayName ? selectedEmployee.displayName : `${selectedEmployee.firstName} ${selectedEmployee.lastName}`,
                                    employeeNumber: selectedEmployee.employeeNumber,
                                    email: selectedEmployee.email,
                                  };
                                }
                                return employee;
                              }),
                            };
                          }
                          return prevRequest;
                        });
                      }}
                      id="controllable-states-demo"
                      options={employeeList}
                      getOptionLabel={(option: IEmployee) => option.displayName ? option.displayName : `${option.firstName} ${option.lastName}`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Employee"
                          variant="standard"
                          sx={{ '& .MuiFormLabel-asterisk': { color: 'warning.main', },}}
                          required
                        />
                      )}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          width="100%"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                          key={option.email}
                        >
                          <Tooltip title={option.email} placement="bottom">
                            <Typography>
                              {option.displayName ? option.displayName : `${option.firstName} ${option.lastName}`}
                            </Typography>
                          </Tooltip>
                        </Box>
                      )}
                    />
                  </Box>
                </Grid>
                <RequestDropdown
                  label="Training Completed"
                  onChange={(event: SelectChangeEvent) =>
                    setRequest((prevRequest) => ({
                      ...prevRequest,
                      requestEmployees: prevRequest.requestEmployees.map((employee, index) => {
                        if (index === employeeIndex) {
                          return {
                            ...employee,
                            trainingCompleted: event.target.value === 'Yes',
                          };
                        }
                        return employee;
                      }),
                    }))
                  }
                  value={employee.trainingCompleted ? 'Yes' : employee.trainingCompleted === undefined ? '' : 'No'}
                  options={ppeTraining}
                  required
                />
                <RequestDropdown
                  label="Co Brand"
                  onChange={(event: SelectChangeEvent) =>
                    setRequest((prevRequest) => ({
                      ...prevRequest,
                      requestEmployees: prevRequest.requestEmployees.map((employee, index) => {
                        if (index === employeeIndex) {
                          return {
                            ...employee,
                            coBrand: event.target.value,
                          };
                        }
                        return employee;
                      }),
                    }))
                  }
                  value={employee.coBrand}
                  options={ppeCoBrand}
                  required
                />
                {/* office field */}
                <Grid item xs={12} lg>
                  <Box m={1}>
                    <FormControl sx={{ minWidth: 120, width: '100%' }} size="medium" required >
                      <InputLabel htmlFor="office-grouped-native-select" sx={{ '& .MuiFormLabel-asterisk': { color: 'warning.main' }, }}>
                        Office
                      </InputLabel>
                      <NativeSelect
                        id="office-grouped-native-select"
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                          setRequest((prevRequest) => ({
                            ...prevRequest,
                            requestEmployees: prevRequest.requestEmployees.map((employee, index) => {
                              if (index === employeeIndex) {
                                return {
                                  ...employee,
                                  office: event.target.value,
                                };
                              }
                              return employee;
                            }),
                          }))
                        }
                        value={employee.office}
                      >
                        <option aria-label="None" value="" />
                        <option aria-label="Remote" value="Remote">
                          &nbsp;&nbsp;&nbsp;&nbsp;Remote
                        </option>
                        {officeList &&
                          Object.keys(officeList).map((state) => (
                            <optgroup label={state} key={state}>
                              {officeList[state].map((city, index) => (
                                <option value={`${city}, ${state}`} key={index}>
                                  {city}, {state}
                                </option>
                              ))}
                            </optgroup>
                          ))}
                      </NativeSelect>
                    </FormControl>
                  </Box>
                </Grid>

                {showWorkFit &&
                  <RequestDropdown
                    label="WorkFit"
                    onChange={(event: SelectChangeEvent) =>
                      setRequest((prevRequest) => ({
                        ...prevRequest,
                        requestEmployees: prevRequest.requestEmployees.map(
                          (employee, index) => {
                            if (index === employeeIndex) {
                              return {
                                ...employee,
                                workFit: event.target.value === 'Yes',
                              };
                            }
                            return employee;
                          }
                        ),
                      }))
                    }
                    value={employee.workFit ? 'Yes' : employee.workFit === undefined ? '' : 'No'}
                    options={workFit}
                    required
                  />
                }

                {employee.office === 'Remote' && (
                  <RequestTextField
                    label="Ship To"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setRequest((prevRequest) => ({
                        ...prevRequest,
                        requestEmployees: prevRequest.requestEmployees.map((employee, index) => {
                          if (index === employeeIndex) {
                            return {
                              ...employee,
                              shipTo: event.target.value,
                            };
                          }
                          return employee;
                        }),
                      }))
                    }
                    value={employee.shipTo}
                    required
                  />
                )}

                <RequestTextField
                  label="Project No. (If applicable)"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setRequest((prevRequest) => ({
                      ...prevRequest,
                      requestEmployees: prevRequest.requestEmployees.map(
                        (employee, index) => {
                          if (index === employeeIndex) {
                            return {
                              ...employee,
                              projectNo: event.target.value,
                            };
                          }
                          return employee;
                        }
                      ),
                    }))
                  }
                  value={employee.projectNo}
                />
                <RequestTextField
                  label="Task"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setRequest((prevRequest) => ({
                      ...prevRequest,
                      requestEmployees: prevRequest.requestEmployees.map((employee, index) => {
                        if (index === employeeIndex) {
                          return {
                            ...employee,
                            task: event.target.value,
                          };
                        }
                        return employee;
                      }),
                    }))
                  }
                  value={employee.task}
                  required={employee.projectNo.trim().length > 0}
                />
                <RequestTextField
                  label="Org"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setRequest((prevRequest) => ({
                      ...prevRequest,
                      requestEmployees: prevRequest.requestEmployees.map((employee, index) => {
                        if (index === employeeIndex) {
                          return {
                            ...employee,
                            org: event.target.value,
                          };
                        }
                        return employee;
                      }),
                    }))
                  }
                  value={employee.org}
                  required={employee.projectNo.trim().length > 0}
                />
              </Grid>
            </Box>
            {/* items section*/}
            <Box>
              {/* add item button */}
              <Box display="flex" justifyContent="left" alignItems="center" m={1}>
                <Button onClick={() => addItem(employeeIndex)} startIcon={<AddIcon />}>
                  Add Equipment
                </Button>
              </Box>
              {/* item rows */}
              {employee.requestItems.map((item, itemIndex) => (
                <Grid container spacing={2} columns={{ md: 66 }} key={itemIndex} m={1}>
                  {/* item detail fields */}
                  <Grid item xs md={60}>
                    <Grid container spacing={2}>
                      <RequestDropdown
                        label="Equipment"
                        onChange={(event: SelectChangeEvent) =>
                          setRequest((prevRequest) => ({
                            ...prevRequest,
                            requestEmployees: prevRequest.requestEmployees.map((employee, empIndex) => {
                              if (empIndex === employeeIndex) {
                                return {
                                  ...employee,
                                  requestItems: employee.requestItems.map((item, idx) => {
                                    if (idx === itemIndex) {
                                      const isStandard = event.target.value !== 'Other Non-Standard Equipment';
                                        return {
                                          category: event.target.value,
                                          isStandard: isStandard,
                                          item: isStandard ? event.target.value
                                            : '',
                                            type: '',
                                            color: '',
                                            size: '',
                                            make: '',
                                            model: '',
                                            quantity: undefined,
                                            unit: ''
                                          };
                                        }
                                        return item;
                                      }
                                    ),
                                  };
                                }
                                return employee;
                              }
                            ),
                          }))
                        }
                        value={item.category}
                        options={ppeItems.map((i) => i.category)}
                        required
                      />
                      {showTypeField(item.category) &&
                        <RequestDropdown
                          label="Type"
                          onChange={(event: SelectChangeEvent) =>
                            setRequest((prevRequest) => ({
                              ...prevRequest,
                              requestEmployees: prevRequest.requestEmployees.map((employee, empIndex) => {
                                if (empIndex === employeeIndex) {
                                  return {
                                    ...employee,
                                    requestItems: employee.requestItems.map((item, idx) => {
                                      if (idx === itemIndex) {
                                        return {
                                          ...item,
                                          type: event.target.value,
                                        };
                                      }
                                      return item;
                                    }),
                                  };
                                }
                                return employee;
                              }),
                            }))
                          }
                          value={item.type}
                          options={(ppeItems.find((i) => i.category === item.category) as any)?.type || []}
                          required
                        />
                      }

                      {showSizeField(item.category) &&
                        <RequestDropdown
                          label="Size"
                          onChange={(event: SelectChangeEvent) =>
                            setRequest((prevRequest) => ({
                              ...prevRequest,
                              requestEmployees: prevRequest.requestEmployees.map((employee, empIndex) => {
                                if (empIndex === employeeIndex) {
                                  return {
                                    ...employee,
                                    requestItems: employee.requestItems.map((item, idx) => {
                                      if (idx === itemIndex) {
                                        return {
                                          ...item,
                                          size: event.target.value,
                                        };
                                      }
                                      return item;
                                    }),
                                  };
                                }
                                return employee;
                              }),
                            }))
                          }
                          value={item.size}
                          options={(ppeItems.find((i) => i.category === item.category) as any)?.size || []}
                          required
                        />
                      }

                      {showColorField(item.category) &&
                        <RequestDropdown
                          label="Color"
                          onChange={(event: SelectChangeEvent) =>
                            setRequest((prevRequest) => ({
                              ...prevRequest,
                              requestEmployees: prevRequest.requestEmployees.map((employee, empIndex) => {
                                if (empIndex === employeeIndex) {
                                  return {
                                    ...employee,
                                    requestItems: employee.requestItems.map((item, idx) => {
                                      if (idx === itemIndex) {
                                        return {
                                          ...item,
                                          color: event.target.value,
                                        };
                                      }
                                      return item;
                                    }),
                                  };
                                }
                                return employee;
                              }),
                            }))
                          }
                          value={item.color}
                          options={(ppeItems.find((i) => i.category === item.category) as any)?.color || []}
                          required
                        />
                      }

                      {showNonStandardFields(item.category) &&
                        <>
                          <RequestTextField
                            label='Item'
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                              setRequest((prevRequest) => ({
                                ...prevRequest,
                                requestEmployees: prevRequest.requestEmployees.map((employee, empIndex) => {
                                  if (empIndex === employeeIndex) {
                                    return {
                                      ...employee,
                                      requestItems: employee.requestItems.map((item, idx) => {
                                        if (idx === itemIndex) {
                                          return {
                                            ...item,
                                            item: event.target.value,
                                          };
                                        }
                                        return item;
                                      }),
                                    };
                                  }
                                  return employee;
                                }),
                              }))
                            }
                            value={item.item || ''}
                            required
                          />

                          <RequestTextField
                            label='Model (specify)'
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                              setRequest((prevRequest) => ({
                                ...prevRequest,
                                requestEmployees: prevRequest.requestEmployees.map((employee, empIndex) => {
                                  if (empIndex === employeeIndex) {
                                    return {
                                      ...employee,
                                      requestItems: employee.requestItems.map((item, idx) => {
                                        if (idx === itemIndex) {
                                          return {
                                            ...item,
                                            model: event.target.value,
                                          };
                                        }
                                        return item;
                                      }),
                                    };
                                  }
                                  return employee;
                                }),
                              }))
                            }
                            value={item.model || ''}
                            required
                          />

                          <RequestTextField
                            label='Make (specify)'
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                              setRequest((prevRequest) => ({
                                ...prevRequest,
                                requestEmployees: prevRequest.requestEmployees.map((employee, empIndex) => {
                                  if (empIndex === employeeIndex) {
                                    return {
                                      ...employee,
                                      requestItems: employee.requestItems.map((item, idx) => {
                                        if (idx === itemIndex) {
                                          return {
                                            ...item,
                                            make: event.target.value,
                                          };
                                        }
                                        return item;
                                      }),
                                    };
                                  }
                                  return employee;
                                }),
                              }))
                            }
                            value={item.make || ''}
                            required
                          />
                        </>
                      }

                      {showQuantityFields(item.category) &&
                        <>
                          <RequestTextField
                            label='Quantity'
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                              setRequest((prevRequest) => ({
                                ...prevRequest,
                                requestEmployees: prevRequest.requestEmployees.map((employee, empIndex) => {
                                  if (empIndex === employeeIndex) {
                                    return {
                                      ...employee,
                                      requestItems: employee.requestItems.map((item, idx) => {
                                        if (idx === itemIndex) {
                                          return {
                                            ...item,
                                            quantity: event.target.value as unknown as number,
                                          };
                                        }
                                        return item;
                                      }),
                                    };
                                  }
                                  return employee;
                                }),
                              }))
                            }
                            value={item.quantity || ''}
                            type= 'number'
                            required
                          />

                          <RequestDropdown
                            label="Unit"
                            onChange={(event: SelectChangeEvent) =>
                              setRequest((prevRequest) => ({
                                ...prevRequest,
                                requestEmployees: prevRequest.requestEmployees.map((employee, empIndex) => {
                                  if (empIndex === employeeIndex) {
                                    return {
                                      ...employee,
                                      requestItems: employee.requestItems.map((item, idx) => {
                                        if (idx === itemIndex) {
                                          return {
                                            ...item,
                                            units: event.target.value,
                                          };
                                        }
                                        return item;
                                      }),
                                    };
                                  }
                                  return employee;
                                }),
                              }))
                            }
                            value={item.units}
                            options={unitOptions}
                            required
                          />
                        </>
                      }
                    </Grid>
                  </Grid>
                  {/* remove item button */}
                  <Grid item xs md={6}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2,}}>
                      <IconButton aria-label="delete" size="large" color="primary" onClick={() => removeItem(employeeIndex, itemIndex)} disabled={employee.requestItems.length === 1}>
                        <CancelIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              ))}
            </Box>
          </Box>
        </Box>
      ))}
      <Box display="flex" justifyContent="center" alignItems="center">
        <Button onClick={addEmployee} startIcon={<AddIcon />} variant="outlined">
          Add Equipment Request For Another Employee
        </Button>
      </Box>
      {/* submit button */}
      <Box display="flex" alignItems="center" justifyContent="center" mt={10} mx={2}>
        <LoadingButton variant="contained" type="submit" size="large" loading={submitLoading}>
          Submit
        </LoadingButton>
      </Box>
      {/* Footer Spacer */}
      <Box height={128}></Box>
    </Box>
  );
};

export default PpeRequestForm;
