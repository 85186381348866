import React from 'react';
import { Typography, Toolbar, Box, Button } from '@mui/material';
import { IRequest } from 'app/models/ppeRequest/request';
import GradeIcon from '@mui/icons-material/Grade';

interface RequestHeaderProps {
  ppeRequest: IRequest
};

const RequestHeader = ({ ppeRequest }: RequestHeaderProps) => {
  return (
    <Toolbar style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'white', margin: 2,}}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
        <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
          Request ID # : {ppeRequest.id}
        </Typography>
        {ppeRequest.newHire && (
          <Button variant="text" startIcon={<GradeIcon />} color="secondary" sx={{ m: 1 }}>New Hire</Button>
        )}
      </Box>
      {/* show request status */}
      <Button
        variant="contained"
        size="small"
        style={{ marginLeft: 16 }}
        sx={{
          bgcolor: `status${ppeRequest.status.replaceAll(' ','')}.light`,
          color: `status${ppeRequest.status.replaceAll(' ','')}.main`,
          borderColor: `status${ppeRequest.status.replaceAll(' ','')}.main`,
          '&:hover': { bgcolor: `status${ppeRequest.status.replaceAll(' ','')}.dark`,},
        }}
      >
        {ppeRequest.status}
      </Button>
    </Toolbar>
  );
};

export default RequestHeader;