import { IOrderStatus } from 'app/models/order';

export const statusToStepMap: Record<IOrderStatus, number> = {
  Requested: 1,
  Approved: 2,
  Rejected: 2,
  Shipped: 3,
  Received: 3,
  'Picked up': 3,
  Returned: 3,
  Restock: 3,
  Canceled: 3,
  Void: 3,
};
