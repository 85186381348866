import React from 'react';
import { TableRow, TableCell, TableHead, TableBody, TableContainer, Table, Paper } from '@mui/material';
import { IRequest } from 'app/models/ppeRequest/request';
import GradeIcon from '@mui/icons-material/Grade';

interface RequestDetailsTableProps {
  ppeRequest: IRequest
};

const RequestDetailsTable = ({ ppeRequest }: RequestDetailsTableProps) => {
  return (
    <TableContainer component={Paper} elevation={3}>
      <Table aria-label="PPE Request Details Table">
        <TableHead>
          <TableRow sx={{ '& th': { backgroundColor: 'secondary.dark', },}}>
            <TableCell>Request Date</TableCell>
            <TableCell>Requester</TableCell>
            <TableCell>Requester Email</TableCell>
            <TableCell>Discipline</TableCell>
            <TableCell>DSC</TableCell>
            <TableCell>Reviewer</TableCell>
            <TableCell>Reviewed Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{new Date(ppeRequest.createdTime as Date).toLocaleDateString()}</TableCell>
            <TableCell>{ppeRequest.requester}</TableCell>
            <TableCell>{ppeRequest.requesterEmail}</TableCell>
            <TableCell>{ppeRequest.discipline}</TableCell>
            <TableCell>{ppeRequest.dsc}</TableCell>
            <TableCell>{ppeRequest.reviewer}</TableCell>
            {ppeRequest.reviewed && (
              <TableCell>{new Date(ppeRequest.reviewed as Date).toLocaleDateString()}</TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RequestDetailsTable;