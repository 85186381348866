import GradeIcon from '@mui/icons-material/Grade';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import PrintIcon from '@mui/icons-material/Print';
import {
  Box,
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import Loading from 'app/common/modals/Loading';
import NotFound from 'app/common/modals/NotFound';
import { formatQuantity } from 'app/common/util/util';
import { useRequestStore } from 'app/stores/requestStore';
import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

const MyRequestDetails = () => {
  /* State and functions from custom hooks */
  const { requestId }: any = useParams();
  const { requestForReview, requestForReviewLoading, setRequestForReview } =
    useRequestStore();
  const navigate = useNavigate();

  /* Define component state variables */
  const componentRef = useRef(null);

  /* Fetch request details on component mount */
  useEffect(() => {
    setRequestForReview(requestId);
  }, [requestId, setRequestForReview]);

  /* Render loading spinner if request is still loading */
  /* Render NotFound component if request is not found */
  if (requestForReviewLoading) {
    return <Loading />;
  } else if (!requestForReview) {
    return <NotFound />;
  }

  return (
    <Box mx={2} mt={2}>
      {/* Back to table and print order buttons */}
      <Box
        m={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          variant="contained"
          size="small"
          startIcon={<KeyboardReturnIcon />}
          onClick={() => {
            navigate(`/my-ppeRequests`);
          }}
        >
          Back to table
        </Button>
        <ReactToPrint
          content={() => componentRef.current}
          trigger={() => (
            <Button variant="contained" size="small" startIcon={<PrintIcon />}>
              Print Request
            </Button>
          )}
        />
      </Box>
      {/* Request details */}
      <Box ref={componentRef}>
        {/* Request ID and Status */}
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'white',
            margin: 2,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
              Request ID # : {requestForReview.id}
            </Typography>
            {requestForReview.newHire && (
              <Button variant="text" startIcon={<GradeIcon />} color="secondary" sx={{ m: 1 }} >
                New Hire
              </Button>
            )}
          </Box>
          <Button
            variant="contained"
            size="small"
            style={{ marginLeft: 16 }}
            sx={{
              width: 100,
              bgcolor: `status${requestForReview.status.replaceAll(' ', ''  )}.light`,
              color: `status${requestForReview.status.replaceAll(' ','')}.main`,
              borderColor: `status${requestForReview.status.replaceAll(' ','')}.main`,
              '&:hover': {
                bgcolor: `status${requestForReview.status.replaceAll(' ','')}.dark`,
              },
            }}
          >
            {requestForReview.status}
          </Button>
        </Toolbar>
        {/* Request Details Table */}
        <TableContainer component={Paper} elevation={3}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow sx={{ '& th': { backgroundColor: 'secondary.dark', }, }}>
                <TableCell>Request Date</TableCell>
                <TableCell>Requester</TableCell>
                <TableCell>Requester Email</TableCell>
                <TableCell>Discipline</TableCell>
                <TableCell>DSC</TableCell>
                <TableCell>Reviewer</TableCell>
                <TableCell>Reviewed Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {new Date(
                    requestForReview.createdTime as Date
                  ).toLocaleDateString()}
                </TableCell>
                <TableCell>{requestForReview.requester}</TableCell>
                <TableCell>{requestForReview.requesterEmail}</TableCell>
                <TableCell>{requestForReview.discipline}</TableCell>
                <TableCell>{requestForReview.dsc}</TableCell>
                <TableCell>{requestForReview.reviewer}</TableCell>
                {requestForReview.reviewed && (
                  <TableCell>
                    {new Date(
                      requestForReview.reviewed as Date
                    ).toLocaleDateString()}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {/* Render employee details */}
        <Box m={2}>
          {requestForReview.requestEmployees.map((e, employeeIndex) => (
            <Box key={e.id}>
              <Chip label={employeeIndex + 1} sx={{ m: 1 }} />
              {/* Employee Details Table */}
              <TableContainer component={Paper} elevation={2}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ '& th': { color: 'white', backgroundColor: 'primary.main',},}}>
                      <TableCell>Employee Number</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Co Brand</TableCell>
                      <TableCell>Office</TableCell>
                      <TableCell>Ship To</TableCell>
                      <TableCell>Training Completed</TableCell>
                      {requestForReview.newHire && (
                        <TableCell>WorkFit</TableCell>
                      )}
                      <TableCell>Project No</TableCell>
                      <TableCell>Task</TableCell>
                      <TableCell>Org</TableCell>
                      <TableCell>Comments</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{e.employeeNumber}</TableCell>
                      <TableCell>{e.name}</TableCell>
                      <TableCell>{e.coBrand}</TableCell>
                      <TableCell>{e.office}</TableCell>
                      <TableCell>{e.shipTo}</TableCell>
                      <TableCell>
                        {e.trainingCompleted ? 'Yes' : 'No'}
                      </TableCell>
                      {requestForReview.newHire && (
                        <TableCell>{e.workFit ? 'Yes' : 'No'}</TableCell>
                      )}
                      <TableCell>{e.projectNo}</TableCell>
                      <TableCell>{e.task}</TableCell>
                      <TableCell>{e.org}</TableCell>
                      <TableCell>{e.comments}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              {/* Request Items Table */}
              <Box m={2}>
                <TableContainer component={Paper} elevation={1} key={e.id}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ '& th': { backgroundColor: 'secondary.dark',},}}>
                        <TableCell>Category</TableCell>
                        <TableCell>Item</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Size</TableCell>
                        <TableCell>Color</TableCell>
                        <TableCell>Make</TableCell>
                        <TableCell>Model</TableCell>
                        <TableCell>Quantity</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {e.requestItems.map((i, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {i.isStandard ? 'Standard' : 'Non-Standard'}
                          </TableCell>
                          <TableCell>{i.item}</TableCell>
                          <TableCell>{i.type}</TableCell>
                          <TableCell>{i.size}</TableCell>
                          <TableCell>{i.color}</TableCell>
                          <TableCell>{i.make}</TableCell>
                          <TableCell>{i.model}</TableCell>
                          <TableCell>{formatQuantity(i.quantity,i.units)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                <Button
                  variant="contained"
                  size="small"
                  style={{ marginLeft: 16 }}
                  sx={{
                    width: 100,
                    bgcolor: `status${e.status.replaceAll(' ', '')}.light`,
                    color: `status${e.status.replaceAll(' ', '')}.main`,
                    borderColor: `status${e.status.replaceAll(' ', '')}.main`,
                    '&:hover': {
                      bgcolor: `status${e.status.replaceAll(' ', '')}.dark`,
                    },
                  }}
                >
                  {e.status}
                </Button>
              </Box>
            </Box>
          ))}
        </Box>

        {/* Footer spacer */}
        <Box height={128}></Box>
      </Box>
    </Box>
  );
};

export default MyRequestDetails;
