export const ppeCoBrand = ['CED', 'CPL', 'Unbranded'];
export const ppeTraining = ['Yes', 'No'];
export const newHire = ['Yes', 'No'];
export const workFit = ['Yes', 'No'];

export const customizedItemImage = '/assets/Customized.png';

export const ppeItems = [
  {
    index: 0,
    category: 'Safety Vest',
    item: 'Safety Vest',
    type: ['Class 2 - Survey', 'Class 3'],
    color: ['Lime (standard)', 'Orange'],
    size: ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
    textFields: [
      {
        field: 'quantity',
        label: 'Quantity',
      },
    ],
    dropdownFields: [
      {
        field: 'type',
        label: 'Type',
      },
      {
        field: 'size',
        label: 'Size',
      },
      {
        field: 'color',
        label: 'Color',
      },
      {
        field: 'units',
        label: 'Units',
      },
    ],
  },
  {
    index: 1,
    category: 'Work Gloves',
    item: 'Work Gloves',
    type: [
      'Coated Palm / Concrete',
      'Cut Resistant',
      'Leather',
      'SUE Mechanics',
      'Nitrile',
    ],
    size: ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
    textFields: [
      {
        field: 'quantity',
        label: 'Quantity',
      },
    ],
    dropdownFields: [
      {
        field: 'type',
        label: 'Type',
      },
      {
        field: 'size',
        label: 'Size',
      },
    ],
  },
  {
    index: 2,
    category: 'Hard Hat',
    item: 'Hard Hat',
    type: [
      'Orange (NYSDOT)',
      'Suspension Only',
      'White - Full Brim',
      'White - Standard Brim',
    ],
    textFields: [
      {
        field: 'quantity',
        label: 'Quantity',
      },
    ],
    dropdownFields: [
      {
        field: 'type',
        label: 'Type',
      },
    ],
  },
  {
    index: 3,
    category: 'Eye/Face Protection',
    item: 'Eye/Face Protection',
    type: [
      'Face Shield',
      'Face Shield w/Goggles',
      'Goggles',
      'Glasses (Clr)',
      'Glasses (Tint)',
      'Glasses-OTG (Clr)',
      'Glasses-OTG (Tint)',
    ],
    textFields: [
      {
        field: 'quantity',
        label: 'Quantity',
      },
    ],
    dropdownFields: [
      {
        field: 'type',
        label: 'Type',
      },
    ],
  },
  {
    index: 4,
    category: 'Hearing Protection',
    item: 'Hearing Protection',
    type: ['Earmuffs', 'Earplugs'],
    textFields: [
      {
        field: 'quantity',
        label: 'Quantity',
      },
    ],
    dropdownFields: [
      {
        field: 'type',
        label: 'Type',
      },
    ],
  },
  {
    index: 5,
    category: 'Leg Protection',
    item: 'Other Protection',
    type: ['Snake Gaiters'],
    textFields: [
      {
        field: 'quantity',
        label: 'Quantity',
      },
    ],
    dropdownFields: [
      {
        field: 'type',
        label: 'Type',
      },
    ],
  },
  {
    index: 6,
    category: 'Other Non-Standard Equipment',
    textFields: [
      {
        field: 'item',
        label: 'Item',
      },
      {
        field: 'model',
        label: 'Model (specify)',
      },
      {
        field: 'make',
        label: 'Make (specify)',
      },
      {
        field: 'quantity',
        label: 'Quantity',
      },
    ],
  },
];

export const unitOptions = [
  "Each",
  "Pair",
  "Pack",
  "Bag",
  "Box",
  "Case",
]
