export interface IRequestItem {
  category: string;
  item: string;
  color: string;
  type: string;
  size: string;
  model: string;
  make: string;
  quantity?: number;
  units?: string;
  isStandard?: boolean;
  itemId?: number;
  shippedQuantity?: number;
  isAdminAdded?: boolean;
}

export class RequestItemFormValues implements IRequestItem {
  category: string = '';
  item: string = '';
  type: string = '';
  color: string = '';
  size: string = '';
  make: string = '';
  model: string = '';
  quantity?: number | undefined;
  units: string = '';
  isStandard?: boolean | undefined;
  itemId?: number | undefined;
}

export class SafetyVestValues extends RequestItemFormValues {
  category: string = 'Safety Vest';
  item: string = 'Safety Vest';
  isStandard?: boolean | undefined = true;
}

export class WorkGlovesValues extends RequestItemFormValues {
  category: string = 'Work Gloves';
  item: string = 'Work Gloves';
  isStandard?: boolean | undefined = true;
}

export class HardHatValues extends RequestItemFormValues {
  category: string = 'Hard Hat';
  item: string = 'Hard Hat';
  isStandard?: boolean | undefined = true;
}

export class EyeFaceProtectionValues extends RequestItemFormValues {
  category: string = 'Eye/Face Protection';
  item: string = 'Eye/Face Protection';
  isStandard?: boolean | undefined = true;
}

export class HearingProtectionValues extends RequestItemFormValues {
  category: string = 'Hearing Protection';
  item: string = 'Hearing Protection';
  isStandard?: boolean | undefined = true;
}

export class LegProtectionValues extends RequestItemFormValues {
  category: string = 'Leg Protection';
  item: string = 'Leg Protection';
  isStandard?: boolean | undefined = true;
}