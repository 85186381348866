import React from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { useUserStore } from 'app/stores/userStore';
import CssBaseline from '@mui/material/CssBaseline';
import Loading from 'app/common/modals/Loading';
import SnackBarInfo from 'app/common/modals/SnackBarInfo';
import AdminDashboard from 'features/admin/AdminDashboard';
import CedCoin from 'features/admin/cedCoin';
import OrderTable from 'features/admin/order';
import OrderDetails from 'features/admin/order/OrderDetails';
import OrderMetrics from 'features/admin/orderMetrics';
import PayrollDeductionTable from 'features/admin/payrollDeduction/PayrollDeductionTable';
import ProductTable from 'features/admin/product';
import ProductDetails from 'features/admin/product/ProductDetails';
import ProductReorder from 'features/admin/productReorder';
import RequestDetails from 'features/admin/request/RequestDetails';
import RequestOrdersTable from 'features/admin/request/RequestOrdersTable';
import RequestOrderDetails from 'features/admin/request/RequestOrderDetails';
import RequestConfirm from 'features/confirm/requestConfirm/RequestConfirm';
import Cart from 'features/cart';
import Checkout from 'features/checkout';
import OrderConfirm from 'features/confirm/orderConfirm';
import Contact from 'features/contact';
import Faq from 'features/faq';
import Footer from 'features/footer/Footer';
import Home from 'features/home';
import ItemDashboard from 'features/item';
import ItemDetails from 'features/item/ItemDetails';
import PpeCustom from 'features/item/PpeCustom';
import MarkReceived from 'features/markReceived';
import MyOrders from 'features/myOrders';
import MyOrderDetails from 'features/myOrders/MyOrderDetails';
import MyPpeRequests from 'features/MyPpeRequests';
import MyRequestDetails from 'features/MyPpeRequests/MyRequestDetails';
import NavBar from 'features/nav';
import StarterPack from 'features/starterPack';
import NotFound from '../common/modals/NotFound';
import RequestTable from 'features/admin/request/RequestTable';
import PpeRequestForm from 'features/PPERequest/PpeRequestForm';
import 'app/layout/styles.css';

/* Layout component to wrap entire application */
const Layout = () => {
  return (
    <>
      <CssBaseline />
      <NavBar />
      <Outlet />
      <SnackBarInfo />
      <Footer />
    </>
  );
};

/* Component to protect routes accessible only to admin users */
const AdminProtectedRoutes = () => {
  const { adminUser, adminUserLoading } = useUserStore();

  return adminUserLoading ? (
    <Loading />
  ) : adminUser.length > 0 ? (
    <Outlet />
  ) : (
    <NotFound error="You have not been granted admin permission for this page" />
  );
};

/* Component to protect routes accessible only to field apparel admin */
const FieldApparelProtectedRoutes = () => {
  const { adminUser } = useUserStore();
  return adminUser.some((u) => u.isSuperAdmin || u.categoryId === 9) ? (
    <Outlet />
  ) : (
    <NotFound error="You have not been granted admin permission for this page" />
  );
};

/* Component to protect routes accessible only to ppe admin */
const PpeProtectedRoutes = () => {
  const { adminUser } = useUserStore();
  return adminUser.some((u) => u.isSuperAdmin || u.categoryId === 6) ? (
    <Outlet />
  ) : (
    <NotFound error="You have not been granted admin permission for this page" />
  );
};

/* Component to protect routes accessible only to ced admins */
// const CedAdminProtectedRoutes = () => {
//   const { user, locations, adminUser } = useUserStore();
//   const cedAdminPermission: boolean = locations.some(
//     (l) =>
//       l.officeLeadsEmail?.toLowerCase() ===
//         user?.userPrincipalName?.toLowerCase() ||
//       l.officeLeadsAdminEmail?.toLowerCase() ===
//         user?.userPrincipalName?.toLowerCase() ||
//       adminUser.some((u) => u.categoryId === 2 || u.categoryId === 1)
//   );

//   console.log('user', user);
//   console.log('locations', locations);
//   console.log('admin', adminUser);
//   console.log('permission', cedAdminPermission);

//   if (!user || locations.length === 0 || adminUser.length === 0)
//     return <Loading />;

//   return cedAdminPermission ? (
//     <Outlet />
//   ) : (
//     <NotFound error="You have not been granted permission for this page" />
//   );
// };

/* Create router configuration */
const router = createBrowserRouter([
  {
    /* Render Layout component as root */
    element: <Layout />,
    errorElement: <NotFound />,
    children: [
      /* Render Home component for root path */
      {
        path: '/',
        element: <Home />,
      },

      /* Render ItemDashboard component for different category */
      {
        path: 'inventory/:catId',
        element: <ItemDashboard />,
      },
      /* Render ItemDetails component */
      {
        path: 'inventory/:catId/:itemId',
        element: <ItemDetails />,
      },
      /* Render PPE custom ItemDetails component */
      {
        path: 'inventory/6/custom',
        element: <PpeCustom />,
      },

      /* Protect admin routes */
      {
        element: <AdminProtectedRoutes />,
        children: [
          /* Render AdminDashboard component with OrderTable as default */
          {
            path: 'admin',
            element: <AdminDashboard table={<OrderTable />} />,
          },
          /* Render AdminDashboard component with OrderTable */
          {
            path: 'admin/order',
            element: <AdminDashboard table={<OrderTable />} />,
          },
          /* Render AdminDashboard component with ProductTable */
          {
            path: 'admin/product',
            element: <AdminDashboard table={<ProductTable />} />,
          },
          /* Render AdminDashboard component with ProductReorder */
          {
            path: 'admin/product/reorder',
            element: <AdminDashboard table={<ProductReorder />} />,
          },
          /* Render AdminDashboard component with OrderMetrics */
          {
            path: 'admin/orderMetrics',
            element: <AdminDashboard table={<OrderMetrics />} />,
          },

          /*  Protect routes for field apparel admin */
          {
            element: <FieldApparelProtectedRoutes />,
            children: [
              {
                path: 'admin/cedcoin',
                element: <AdminDashboard table={<CedCoin />} />,
              },
              /* Render AdminDashboard component with OrderMetrics */
              {
                path: 'admin/payrollDeduction',
                element: <AdminDashboard table={<PayrollDeductionTable />} />,
              },
            ],
          },
          /* Protect routes for PPE requests admin */
          {
            element: <PpeProtectedRoutes />,
            children: [
              /* Render AdminDashboard component with Request table */
              {
                path: 'admin/ppeRequest/:requestPageSize/:requestPage',
                element: <AdminDashboard table={<RequestTable />} />,
              },
              /* Render RequestDetails component  */
              {
                path: 'admin/ppeRequest/:requestPageSize/:requestPage/:requestId',
                element: <RequestDetails />,
              },
              /* Render AdminDashboard component with Request Orders table */
              {
                path: 'admin/ppeRequestOrder/:requestPageSize/:requestPage',
                element: <AdminDashboard table={<RequestOrdersTable />} />,
              },
              /* Render RequestOrderDetails component  */
              {
                path: 'admin/ppeRequestOrder/:requestPageSize/:requestPage/:requestId',
                element: <RequestOrderDetails />,
              },
            ],
          },
          /* Render ProductDetails component */
          {
            path: 'admin/product/:catId/:productId',
            element: <ProductDetails />,
          },
          /*  Render OrderDetails component */
          {
            path: 'admin/order/:orderId',
            element: <OrderDetails />,
          },
        ],
      },
      /* Render Checkout component */
      {
        path: 'checkout',
        element: <Checkout />,
      },
      /* Render Cart component */
      {
        path: 'cart',
        element: <Cart />,
      },
      /* Render Contact component */
      {
        path: 'contact',
        element: <Contact />,
      },
      /* Render Faq component */
      {
        path: 'faq',
        element: <Faq />,
      },
      /* Render MarkReceived component */
      {
        path: '/mark-received/:orderId',
        element: <MarkReceived />,
      },
      /* Render MyOrders component */
      {
        path: 'my-orders',
        element: <MyOrders />,
      },
      /* Render MyOrderDetails component  */
      {
        path: 'my-orders/:orderId',
        element: <MyOrderDetails />,
      },
      /* Render MyPpeRequests component  */
      {
        path: 'my-ppeRequests',
        element: <MyPpeRequests />,
      },
      /* Render MyRequestDetails component  */
      {
        path: 'my-ppeRequests/:requestId',
        element: <MyRequestDetails />,
      },
      /* Render OrderConfirm component  */
      {
        path: 'confirmation',
        element: <OrderConfirm />,
      },
      /* Render PpeRequest component  */
      {
        path: 'ppe-request',
        element: <PpeRequestForm />,
      },
      /* Render PpeRequest component  */
      {
        path: 'ppe-request/newhire',
        element: <PpeRequestForm />,
      },
      /* Render RequestConfirm component  */
      {
        path: 'request-confirmation',
        element: <RequestConfirm />,
      },
      /* Render marketing starter pack component  */
      {
        path: 'marketing-starter-pack',
        element: <StarterPack />,
      },
    ],
  },
]);

const App = () => {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <RouterProvider router={router}></RouterProvider>
    </MsalAuthenticationTemplate>
  );
};

export default App;
